import useSendbirdStateContext from "@sendbird/uikit-react/useSendbirdStateContext";
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams,useNavigate } from 'react-router-dom'
import { getPatientDetails } from '../redux/actions/passActions'
import DocumentRequest from './payment/DocumentRequest'
import PayRequest from './payment/PayRequest'
import AddPaymentRequest from './payment/PayRequestForm'
import AddDocumentRequest from './payment/RequestDocumentForm'
import PrevChatPage from './shared/chat/prev/prevchatPage'
import Modal from './shared/modal'
import { Icon } from '@iconify/react'
import { toastError } from "../utils/helpers/methods";
import { Badge } from "flowbite-react";
import { unToogleFilter } from "../redux/slices/chat";
import { locationUIInfo } from "./shared/CityCode";

const PatientDetail = () => {
  const { patientId } = useParams()
  const dispatch = useDispatch()
  const { Patientinfo } = useSelector((state) => state.pass)
  const { userToken } = useSelector((state) => state.auth)
  const [openModal, setOpenModal] = useState(false)
  const [dependantId, setDependentId] = useState('')
  const [depenentModal, setDependentModal] = useState(false)
  const [generalId, setGeneralId] = useState('')
  const store = useSendbirdStateContext()
  var sdk = store?.stores.sdkStore.sdk
  const navigate=useNavigate()

  useEffect(() => {
    if (patientId) {
      setGeneralId(patientId)
    }
    if (dependantId) {
      setGeneralId(dependantId)
    }
  }, [patientId, dependantId])

  useEffect(() => {
    //   dispatch(getListPasswords({ token: userToken }));
    dispatch(getPatientDetails({ token: userToken, patientId: generalId }))
    //eslint-disable-next-line
  }, [generalId])
  const handleReverse = (data) => {
    const rev = [...data].reverse()
    return rev
  }
  const [activeTab, setActiveTab] = useState(1)

  const handleTabClick = (tabIndex) => {
    setActiveTab(tabIndex)
  }
  const handleRadioChange = (event) => {
    setDependentId(event.target.value)
    setDependentModal(false)
    handleTabClick(1)
  }

 const gotoChat=async(patientinfo)=>{
    var user = patientinfo.patient_login.sendbird_user_id != undefined && patientinfo.patient_login.sendbird_user_id != null
    if(user!=undefined){ 
      dispatch(unToogleFilter())
      navigate('/chat',{ state: patientinfo })
    } else{
      toastError("Not able to chat at this moment.")
    }
    }
  
  return (
    <div className='container mx-auto py-8'>
      <div className='w-full flex md:flex-row flex-col justify-between'>
        <Icon
          icon={'ep:back'}
          fontSize={30}
          className='text-everglade-800 cursor-pointer'
          onClick={() => navigate('/patientList')}
        />
        <div className='flex justify-center items-center w-full mb-10'>
          <h1 className='text-[25px] ml-0 font-bold text-everglade-800'>
            Patient Details
          </h1>
        </div>
        <div className='flex flex-row gap-5 items-center'>
          {/* <button className='w-[100px] h-[35px] bg-indigo-500 rounded-full flex flex-row justify-center items-center gap-2'>
                    <Icon icon='ic:round-plus' color='#ffbe0b' fontSize={18} />
                    <p className='text-white'>New</p>
                </button>
                <div className='relative'>
                    <input className='w-[80%] h-[40px] rounded-xl border-[1px] border-[#c4c4c4] focus:border-indigo-500 pl-[40px]' placeholder='Search' />
                    <Icon icon='ic:sharp-search' color='#4361ee' fontSize={24} className='absolute top-2 left-2' />
                </div> */}
        </div>
      </div>
      <div  className= 'flex flex-col items-center'>
                    
                        <div >
                        <h1 className='text-[25px] mr-0 font-bold text-everglade-800'>
                        {Patientinfo?.first_name} {Patientinfo?.last_name}
          </h1>
                          

                        </div>
                        <div  className='flex flex-row items-center justify-between'>
                          {locationUIInfo(Patientinfo)}
                          {Patientinfo?.patients?.is_vip==1 && <Icon icon="quill:vip" fontSize={40} color='#ffbf00'></Icon>}
                          {/* {Patientinfo?.patients?.is_vip==1 &&  <Badge className="ml-1" color="warning">VIP</Badge>} */}
                          {Patientinfo?.patients  &&  <Badge className="ml-1" size={40} color={(Patientinfo?.patients.deleted_at==null||Patientinfo?.patients.deleted_at=='')?'success':'failure'}>{(Patientinfo?.patients.deleted_at==null||Patientinfo?.patients.deleted_at=='')?'Active':'InActive'}</Badge>}
                          {Patientinfo?.employee_details?.location_group?.group_name  &&  <Badge className="ml-1" color="blue" size={40}>{Patientinfo?.employee_details?.location_group?.group_name}</Badge>}
                        </div>
                      </div>
      <div className='w-full mx-auto'>
        <div className=''>
          <div className='container md:mx-auto flex items-center justify-between px-4 py-2'>
            <div className='flex md:flex-row flex-col w-full'>
              <div className='flex border-b border-gray-300 w-full  '>
                <div
                  className={`flex-1 text-center px-4 py-2  w-full uppercase cursor-pointer font-medium ${
                    activeTab === 1
                      ? 'border-b-2 border-everglade-500 text-everglade-500'
                      : 'text-everglade-900'
                  }`}
                  onClick={() => handleTabClick(1)}
                >
                  Personal Information
                </div>
                <div
                  className={`flex-1 text-center px-4 py-2 uppercase cursor-pointer font-medium ${
                    activeTab === 2
                      ? 'border-b-2 border-everglade-500 text-everglade-500'
                      : 'text-everglade-900'
                  }`}
                  onClick={() => handleTabClick(2)}
                >
                  Employer Info
                </div>
                <div
                  className={`flex-1 text-center px-4 py-2 uppercase cursor-pointer font-medium ${
                    activeTab === 3
                      ? 'border-b-2 border-everglade-500 text-everglade-500'
                      : 'text-everglade-900'
                  }`}
                  onClick={() => handleTabClick(3)}
                >
                  Insurance Information
                </div>
                {Patientinfo?.patient_entry != null && (
                  <div
                    className={`flex-1 text-center px-4 py-2 uppercase cursor-pointer font-medium ${
                      activeTab === 4
                        ? 'border-b-2 border-everglade-500 text-everglade-500'
                        : 'text-everglade-900'
                    }`}
                    onClick={() => handleTabClick(4)}
                  >
                    Payment Request
                  </div>
                )}
                {Patientinfo?.patient_entry != null && (
                  <div
                    className={`flex-1 text-center px-4 py-2 uppercase cursor-pointer font-medium ${
                      activeTab === 5
                        ? 'border-b-2 border-everglade-500 text-everglade-500'
                        : 'text-everglade-900'
                    }`}
                    onClick={() => handleTabClick(5)}
                  >
                    Request Document
                  </div>
                )}
                {Patientinfo?.patient_sbholds.length > 0 && (
                  <div
                    className={`flex-1 text-center px-4 py-2 uppercase cursor-pointer font-medium ${
                      activeTab === 6
                        ? 'border-b-2 border-everglade-500 text-everglade-800'
                        : 'text-everglade-900'
                    }`}
                    onClick={() => handleTabClick(6)}
                  >
                    Previous Provider chats
                  </div>
                )}
              </div>
            </div>
          </div>
          {activeTab === 1 && (
            <div className='mt-4 flex justify-end'>
              <div className='flex  flex-row items-center gap-5'>
                {
                Patientinfo?.patient_login !== null && 
                Patientinfo?.patient_login.sendbird_user_id!=null && 
                Patientinfo?.patient_login.sendbird_user_id!="" &&
                Patientinfo?.patient_entry!=null &&
                Patientinfo?.patients.practice!=null&&
                Patientinfo?.patients.practice.def_provider_id!=null &&
                 (
                  <Icon
                    icon={'humbleicons:chat'}
                    fontSize={30}
                    className='text-everglade-500 cursor-pointer'
                    onClick={()=> gotoChat(Patientinfo)}
                  />
                )}

                <span
                  className={` ${
                    Patientinfo?.patient_login?.dependant_ids?.length > 1 &&
                    'mr-5 p-2 bg-everglade-600 text-white rounded-md'
                  }`}
                >
                  {Patientinfo?.patient_login?.dependant_ids?.length > 1 && (
                    <span
                      onClick={() => setDependentModal(true)}
                      className=' hover:cursor-pointer'
                    >
                      {' '}
                      Switch dependent{' '}
                    </span>
                  )}
                </span>
              </div>
            </div>
          )}
        </div>
        {Patientinfo && (
          <div className='mt-0'>
            {activeTab === 1 && (
              <div className='px-4 py-5 sm:p-6'>
                {/* <h3 className="text-lg leading-6 font-medium text-gray-900">
              Tab 1 Content
            </h3> */}
                <div className='mt-2 max-w  '>
                  <div className='bg-white rounded-lg shadow-gray-800/40 shadow p-6 flex '>
                    {/* <h2 className="text-xl font-bold mb-4">Personal Information</h2> */}
                    <div>
                      <div className='mb-4 flex flex-row items-center'>
                        <span className='font-bold mr-2 w-[200px] text-lg text-everglade-800'>
                          Name:
                        </span>
                        <span  className='flex flex-row items-center'>
                          {Patientinfo?.first_name} {Patientinfo?.last_name} 
                          {Patientinfo?.patients?.is_vip==1 && <Icon className="ml-2" icon="quill:vip" fontSize={24} color='#ffbf00'></Icon>}
                        </span>
                      </div>
                      <div className='mb-4 flex flex-row items-center'>
                        <span className='font-bold mr-2 w-[200px] text-lg  text-everglade-800'>
                          Email Address:
                        </span>
                        <span>{Patientinfo?.email_address}</span>
                      </div>
                      <div className='mb-4 flex flex-row items-center'>
                        <span className='font-bold mr-2 w-[200px] text-lg  text-everglade-800'>
                          Date of Birth:
                        </span>
                        <span>{Patientinfo?.dob}</span>
                      </div>
                      <div className='mb-4 flex flex-row items-center'>
                        <span className='font-bold mr-2 w-[200px] text-lg text-everglade-800'>
                          Relationship:
                        </span>
                        <span>{Patientinfo?.relationship}</span>
                      </div>
                      <div className='mb-4 flex flex-row items-center'>
                        <span className='font-bold mr-2 w-[200px] text-lg text-everglade-800'>
                          Gender:
                        </span>
                        <span>
                          {Patientinfo?.gender == 'F' ? 'Female' : 'Male'}
                        </span>
                      </div>
                      <div className='mb-4 flex flex-row items-center'>
                        {Patientinfo?.employer_tag.map((a) => (
                          <button
                            key={a.id}
                            className='text-white font-bold py-2 my-2 mx-2 px-2 rounded'
                            style={{ backgroundColor: a.colour_code }}
                          >
                            {a.tag_name}
                          </button>
                        ))}
                        {Patientinfo?.patient_tags.map((a) => (
                          <button
                            key={a.id}
                            className='text-white font-bold py-2 my-2 mx-2 px-2 rounded'
                            style={{ backgroundColor: a.colour_code }}
                          >
                            {a.tag_name}
                          </button>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {activeTab === 2 && (
              <div className='px-4 py-5 sm:p-6 '>
                <div className='mt-2 max-w text-sm  h-full'>
                  <div className='bg-white rounded-lg shadow-gray-800/40 shadow p-6 flex '>
                    <div>
                      {/* <h2 className="text-xl font-bold mb-4">Employer Info</h2> */}
                      {Patientinfo?.relationship == 'Employee' ||
                      Patientinfo?.relationship == 'Self' ? (
                        <div>
                          <div className='mb-4  flex flex-row items-center'>
                            <span className='font-bold mr-2 w-[200px] text-lg text-everglade-800'>
                              Current Employer :
                            </span>
                            <span>
                              {
                                Patientinfo?.employee_details?.location_group?.group_name
                              }
                            </span>
                          </div>

                          <div className='mb-4 flex flex-row items-center'>
                            <span className='font-bold mr-2 w-[200px] text-lg text-everglade-800'>
                              Dependants :
                            </span>
                            {Patientinfo?.patient_login?.dependant_ids?.length ? (
                              Patientinfo?.patient_login?.dependant_ids.map(
                                (a) => (
                                  <span>
                                    {' '}
                                    {a.address.first_name +
                                      ' ' +
                                      a.address.last_name}{' '}
                                    /
                                  </span>
                                )
                              )
                            ) : (
                              <span>N/A</span>
                            )}
                          </div>
                        </div>
                      ) : (
                        <div className='mb-4 flex flex-row items-center text-xl'>
                          Dependent of
                          <div className='flex justify-between items-center'>
                            <div>
                              <span className='pl-3 font-bold text-everglade-800'>
                                {' '}
                                {
                                  Patientinfo?.employee_details?.address?.first_name
                                }{' '}
                                {Patientinfo?.employee_details?.address?.last_name}
                              </span>{' '}
                              <span className='pl-3'> employee of </span>
                              <span className='pl-3 font-bold text-everglade-800'>
                                {' '}
                                {
                                  Patientinfo?.employee_details?.location_group.group_name
                                }{' '}
                              </span>{' '}
                              <span className='pl-3'>employer</span>
                            </div>
                          </div>
                        </div>
                      )}

                      <div className='mb-4 flex flex-row items-center'>
                        <span className='font-bold mr-2 w-[200px] text-lg text-everglade-800'>
                          Subscriber ID:
                        </span>
                        <span>
                          {Patientinfo?.employee_details.subscriber_id}
                        </span>
                      </div>
                      <div className='mb-4 flex flex-row items-center'>
                        <span className='font-bold mr-2 w-[200px] text-lg text-everglade-800'>
                          Group:
                        </span>
                        <span>
                          S1{Patientinfo?.employee_details?.location_group?.groups}
                        </span>
                      </div>
                      <div className='mb-4 flex flex-row items-center'>
                        <span className='font-bold mr-2 w-[200px] text-lg text-everglade-800'>
                          Status:
                        </span>
                        <span>{Patientinfo?.patient_status}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {activeTab === 3 && (
              <div className='px-4 py-5 sm:p-6'>
                <div className='mt-2 max-w text-sm  h-full'>
                  <div className='bg-white rounded-lg shadow-gray-800/40 shadow p-6 flex'>
                    <div>
                      <div className='mb-4 flex flex-row items-center'>
                        <span className='font-bold mr-2 w-[200px] text-lg text-everglade-800'>
                          Carrier Name:
                        </span>
                        <span>{Patientinfo?.insurance_data?.carrier_name}</span>
                      </div>
                      <div className='mb-4 flex flex-row items-center'>
                        <span className='font-bold mr-2 w-[200px] text-lg text-everglade-800'>
                          Coverage From:
                        </span>
                        <span>{Patientinfo?.insurance_data?.coverage_from}</span>
                      </div>
                      <div className='mb-4 flex flex-row items-center'>
                        <span className='font-bold mr-2 w-[200px] text-lg text-everglade-800'>
                          Coverage Thru:
                        </span>
                        <span>{Patientinfo?.insurance_data?.coverage_thru} </span>
                      </div>
                      <div className='mb-4 flex flex-row items-center'>
                        <span className='font-bold mr-2 w-[200px] text-lg text-everglade-800'>
                          Coverage:
                        </span>
                        <span>{Patientinfo?.insurance.coverage}</span>
                      </div>
                      <div className='mb-4 flex flex-row items-center'>
                        <span className='font-bold mr-2 w-[200px] text-lg text-everglade-800'>
                          Plan Name:
                        </span>
                        <span>{Patientinfo?.insurance_data?.plan_type}</span>
                      </div>
                      <div className='mb-4 flex flex-row items-center'>
                        <span className='font-bold mr-2 w-[200px] text-lg text-everglade-800'>
                          Member Id:
                        </span>
                        <span>{Patientinfo?.insurance_data?.member_id}</span>
                      </div>
                      <div className='mb-4 flex flex-row items-center'>
                        <span className='font-bold mr-2 w-[200px] text-lg text-everglade-800'>
                          Tier :
                        </span>
                        <span>{Patientinfo?.insurance_data.tier}</span>
                      </div>
                      <div className='mb-4 flex flex-row items-center'>
                        <span className='font-bold mr-2 w-[200px] text-lg text-everglade-800'>
                          Tpa Name :
                        </span>
                        <span>{Patientinfo?.insurance_data?.tpa_name}</span>
                      </div>
                      <div className='mb-4 flex flex-row items-center'>
                        <span className='font-bold mr-2 w-[200px] text-lg text-everglade-800'>
                          Sub Group :
                        </span>
                        <span>{Patientinfo?.insurance_data?.sub_group}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {activeTab === 4 && (
              <>
                {openModal && (
                  <Modal title={"Add Payment Request"}
                    className='w-full lg:w-1/2 shadow-2xl'
                    close={() => setOpenModal(false)}
                  >
                    <AddPaymentRequest
                      patientId={Patientinfo && Patientinfo?.patient_id}
                      close={() => setOpenModal(false)}
                    />
                  </Modal>
                )}
                <div className='px-4 py-5 sm:p-6'>
                  <div className=' max-w text-sm  h-full'>
                    <div className='rounded-lg shadow-gray-800/20  p-6 min-h-[600px] overflow-auto'>
                      {Patientinfo && (
                        <PayRequest
                          setOpenModal={() => setOpenModal(true)}
                          data={handleReverse(Patientinfo?.careapp_payreq)}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </>
            )}

            {activeTab === 5 && (
              <>
                {openModal && (
                  <Modal
                    title={"Add Request Document"}
                    className='w-full lg:w-1/2 shadow-2xl'
                    close={() => setOpenModal(false)}
                  >
                    <AddDocumentRequest
                      patientId={Patientinfo && Patientinfo?.patient_id}
                      close={() => setOpenModal(false)}
                    />
                  </Modal>
                )}
                <div className='px-4 py-5 sm:p-6'>
                  <div className='mt-2 max-w text-sm  h-full'>
                    <div className='rounded-lg shadow-gray-800/20  overflow-auto'>
                      {Patientinfo && (
                        <DocumentRequest
                          setOpenModal={() => setOpenModal(true)}
                          data={handleReverse(
                            Patientinfo?.careapp_document_req
                          )}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </>
            )}
            {activeTab === 6 && (
              <>
                <PrevChatPage data={Patientinfo?.patient_sbholds} />
              </>
            )}
            {depenentModal && (
              <Modal
              title={"Switch To Dependent"}
              className='w-[500px] shadow-2xl'
              close={() => setDependentModal(false)}
            >
              <div className='w-full mb-10 '>
        
                {Patientinfo?.patient_login.dependant_ids.map((a) => (
                  <div className='flex flex-col items-center w-full'>
                    <div className='flex justify-between  items-center'>
                      <span className='w-[150px] mt-2'>
                        {a.address.first_name + ' ' + a.address.last_name}
                      </span>
                      <span className='w-[150px] text-everglade-600 font-bold mt-2'>
                        {' '}
                        ({a.address.relationship})
                      </span>
                      <input
                        type='radio'
                        value={a.id}
                        onChange={handleRadioChange}
                        className='mt-2'
                        checked={
                          parseInt(dependantId) === a.id ||
                          parseInt(generalId) === a.id
                        }
                      />
                    </div>
                  </div>
                ))}
              </div>
            </Modal>

            )}
          </div>
        )}
      </div>
    </div>
  )
}

export default PatientDetail
