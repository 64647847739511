import { SENDBIRD_INFO } from "../helpers/constant";
import { toastError } from "../helpers/methods";

// Delay function to prevent 429 errors
const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));

const hideUserFromChannel = async (channelUrl, userId, retryCount = 3) => {
  try {
    const response = await fetch(
      `https://api-${SENDBIRD_INFO.appId}.sendbird.com/v3/group_channels/${channelUrl}/hide`,
      {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Api-Token': SENDBIRD_INFO.sendBirdToken,
        },
        body: JSON.stringify({ user_id: userId, allow_auto_unhide: true }),
      }
    );

    if (response.status === 429 && retryCount > 0) {
      console.warn(`Rate limit hit for user ${userId}, retrying in 2s...`);
      await delay(2000); // Wait 2 sec before retrying
      return hideUserFromChannel(channelUrl, userId, retryCount - 1);
    }

    if (!response.ok) throw new Error(`Failed to hide user ${userId}, Status: ${response.status}`);

    return true;
  } catch (error) {
    console.error(`Error hiding user ${userId}:`, error);
    return false;
  }
};

const removeDataOnAssignedChat = async (channel, cb, toast) => {
  try {
    var dataParams = { data: '' };
    var hideList = channel.members.filter(x => !x.userId.startsWith('PA'));

    // **Step 1: Hide users one by one (to avoid 429)**
    for (let user of hideList) {
      const success = await hideUserFromChannel(channel.url, user.userId);
      if (!success) console.warn(`Skipping user ${user.userId} due to failure.`);
      await delay(500); // Small delay between each request
    }

    // **Step 2: Now update the group channel**
    const updateResponse = await fetch(
      `https://api-${SENDBIRD_INFO.appId}.sendbird.com/v3/group_channels/${channel.url}`,
      {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Api-Token': SENDBIRD_INFO.sendBirdToken,
        },
        body: JSON.stringify(dataParams),
      }
    );

    if (!updateResponse.ok) throw new Error("Failed to update channel");

    cb(); // Callback on success
  } catch (error) {
    console.error("Error in removeDataOnAssignedChat:", error);
    toastError(error);
  }
};

export default removeDataOnAssignedChat;
