import useSendbirdStateContext from '@sendbird/uikit-react/useSendbirdStateContext'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getPatientDetails } from '../redux/actions/passActions'
import { createChannel, toastError } from '../utils/helpers/methods'
import Avatar from './shared/chat/avatar'
import { DoctorDetailsRequest } from '../redux/actions/authActions'
import { backendURL } from '../config/api'

export default function ForwardMessageModal({
  close,
  message,
  data,
  id,
  unClose,
  isTeam,
  filteredChannels,
  forwardedFrom,
  channel,
}) {
  const [activeTab, setActiveTab] = useState('tab1')
  const [selectedItems, setSelectedItems] = useState([])
  const [selectedRelatives, setSelectedRelatives] = useState([])
  const { userToken, userInfo, doctorDetails } = useSelector(
    (state) => state.auth
  )
  const dispatch = useDispatch()

  const { Patientinfo } = useSelector((state) => state.pass)

  const [dependants, setDependents] = useState([])

  const [filteredUsers, setFilteredUsers] = useState([])

  const handleTabClick = (tab) => {
    setActiveTab(tab)
  }

  useEffect(() => {
    if (id) {
      let stringId = id.substring(2)
      dispatch(getPatientDetails({ token: userToken, patientId: stringId }))
    }
  }, [id])

  function generateRandomNumericUserId(length) {
    const characters = '0123456789'
    let randomUserId = ''

    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length)
      randomUserId += characters.charAt(randomIndex)
    }

    return randomUserId
  }

  const adminObject = {
    id: generateRandomNumericUserId(6), // assign a unique ID,
    name: 'ConnectCare Team',
    email_address: '',
    doctor_login: {
      practitioner_id: '',
      sendbird_user_id: 'AD4',
    },
    location: {
      practitioner_id: '',
      phone_number: 0,
    },
    url: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSC-Is9vPh4AgDCULO33AmoIpSvXcaJ51VrRZQUDXxl0YjDDAq94jciUm7zt7SB4OD5UhA&usqp=CAU',
  }

  useEffect(() => {
    if (Patientinfo) {
      const available = Patientinfo?.patient_login?.dependant_ids?.filter(
        (item) => {
          return (
            item.patient_login !== null &&
            item?.patient_login !== null && 
            item.id !== Patientinfo?.patient_login?.patient_id &&
            item?.patient_login.sendbird_user_id!=null && 
            item?.patient_login.sendbird_user_id!="" &&
            //item?.patient_entry!=null &&
            item?.practice!=null&&
            item?.practice?.def_provider_id!=null
          )
        }
      )
      setDependents(available)
    }
  }, [Patientinfo])

  const [available, setAvailable] = useState([])

  const store = useSendbirdStateContext()

  useEffect(() => {
    if (available.length && filteredChannels.length) {
      const filteredArray =
        available.length &&
        available.filter((item) => {
          return (
            !item.doctor_login ||
            (!filteredChannels.some(
              (obj) => obj.userId === item.doctor_login.sendbird_user_id
            ) &&
              !(
                filteredChannels.some((obj) => obj.nickname === 'Admin') &&
                item.name === 'ConnectCare Team'
              ))
          )
        })
      setFilteredUsers(filteredArray)
    } else {
      setFilteredUsers(available)
    }
  }, [available, filteredChannels])

  var sdk = store?.stores.sdkStore.sdk

  let url = `${backendURL}/img/practitioner`

  useEffect(() => {
    let k = []
    const available = data?.data?.practitioners_internal?.filter(
      (item) => item.doctor_login !== null
    )
    if (
      channel &&
      channel.name &&
      channel.name.length &&
      channel.name !== 'Admin'
    ) {
      k = [...available, adminObject]
    } else {
      k = [...available]
    }
    setAvailable(k)
    //eslint-disable-next-line
  }, [data])

  const handleItemClick = (item) => {
    setSelectedItems((prevSelectedItems) => {
      if (prevSelectedItems.includes(item)) {
        return prevSelectedItems.filter((selectedItem) => selectedItem !== item)
      } else {
        return [...prevSelectedItems, item]
      }
    })
  }

  const handleRelativeClick = (item) => {
    setSelectedRelatives((prevSelectedItems) => {
      if (prevSelectedItems.includes(item)) {
        return prevSelectedItems.filter((selectedItem) => selectedItem !== item)
      } else {
        return [...prevSelectedItems, item]
      }
    })
  }

  async function Forward() {
    const combinedSelectedItems = [...selectedItems, ...selectedRelatives]
    const messageData = message?.data ? JSON.parse(message?.data) : null
    const forward = messageData
      ? { name: forwardedFrom, ...messageData }
      : { name: forwardedFrom }

    if (combinedSelectedItems.length) {
      combinedSelectedItems.forEach(async (item) => {
        if (item && item.id !== undefined) {
          var user =
            item.doctor_login != undefined
              ? item.doctor_login.sendbird_user_id != undefined &&
                item.doctor_login.sendbird_user_id != null
              : item.patient_login.sendbird_user_id != undefined &&
                item.patient_login.sendbird_user_id != null

          if (user) {
            var channelName
            var userList

            if (item.doctor_login !== undefined) {
              channelName = 'Teams'
              userList = [item.doctor_login.sendbird_user_id]
            } else {
              userList =
                item.practice !== null &&
                item.practice.providers !== null &&
                item.practice.providers
                  .filter((x) => x.doctor_login !== null)
                  .map((x) => x.doctor_login.sendbird_user_id)

              userList.push(item.patient_login.sendbird_user_id)
              channelName = 'DPCDoctor'
            }

            if (item.name == 'ConnectCare Team') {
              dispatch(DoctorDetailsRequest({ id: userInfo?.id }))
              if (
                doctorDetails &&
                doctorDetails.data &&
                doctorDetails.data.sendbird_admin &&
                doctorDetails.data.sendbird_admin.length
              ) {
                createChannel(
                  doctorDetails.data.sendbird_admin,
                  'Admin',
                  sdk,
                  channelName
                ).then((channel) => {
                  const sendmessage = {}
                  if (message) {
                    sendmessage.message = message//.message
                    sendmessage.data = JSON.stringify(forward)
                    channel
                      .sendUserMessage(sendmessage)
                      .onSucceeded((message, err) => {
                        close()
                        unClose && unClose()
                      })
                  }
                })
              }
            } else {
              createChannel(userList, channelName, sdk, channelName).then(
                (channel) => {
                  const sendmessage = {}
                  if (message) {
                    sendmessage.data = JSON.stringify(forward)
                    sendmessage.message = message//?.message
                    channel
                      .sendUserMessage(sendmessage)
                      .onSucceeded((message, err) => {
                        close()
                        unClose && unClose()
                      })
                  }
                }
              )
            }
          } else {
            toastError('Sorry Unable to forward this message.')
            close()
            unClose && unClose()
          }
        }
      })
    }
  }

  return (
    <div className='w-[800px] text-center'>
      <div>
        <div className='flex border-b border-gray-300'>
          <div
            className={`flex-1 text-center px-4 py-2 uppercase cursor-pointer font-medium ${
              activeTab === 'tab1'
                ? 'border-b-2 border-everglade-500 text-everglade-500'
                : 'text-everglade-900'
            }`}
            onClick={() => handleTabClick('tab1')}
          >
            Teams
          </div>
          {!isTeam && (
            <div
              className={`flex-1 text-center px-4 py-2 uppercase cursor-pointer font-medium ${
                activeTab === 'tab2'
                  ? 'border-b-2 border-everglade-500 text-everglade-500'
                  : 'text-everglade-900'
              }`}
              onClick={() => handleTabClick('tab2')}
            >
              Family members
            </div>
          )}
        </div>
        <div className='p-4'>
          {activeTab === 'tab1' &&
            filteredUsers?.map((item, index) => {
              const isSelected = selectedItems.includes(item)
              return (
                <section
                  className={`flex space-x-4 p-2 flex-row items-center cursor-pointer  ${
                    isSelected ? ' bg-everglade-300 text-white' : ''
                  }`}
                  key={index}
                  onClick={() => handleItemClick(item)}
                >
                  <Avatar
                    fname={item?.name}
                    image={
                      item.profile_image
                        ? `${url}/${item.profile_image}`
                        : item?.url
                        ? item.url
                        : ''
                    }
                  />
                  <p className='capitalize'>{item?.name}</p>
                </section>
              )
            })}
          {activeTab === 'tab2' &&
            (dependants.length === 0 ? (
              <div>
                <p>Patient has no family members</p>
              </div>
            ) : (
              dependants.map((item, index) => {
                const isSelected = selectedRelatives.includes(item)
                return (
                  <section
                    key={index}
                    className={`flex space-x-5 p-2  flex-row items-center cursor-pointer  ${
                      isSelected ? ' bg-everglade-300 text-white' : ''
                    }`}
                    onClick={() => handleRelativeClick(item)}
                  >
                    <Avatar
                      fname={item.address.first_name}
                      lname={item.address.last_name}
                      width={'w-9 h-9'}
                    />
                    <div className='flex flex-row items-center gap-2'>
                      <p className='capitalize'> {item.address.first_name} </p>
                      <p className='capitalize'> {item.address.last_name} </p>
                    </div>
                  </section>
                )
              })
            ))}
        </div>
      </div>
      <div className='flex  justify-start px-8 pb-2'>
        {(selectedItems.length > 0 ||
          selectedRelatives.length > 0 ||
          (selectedItems.length > 0 && selectedRelatives.length > 0)) && (
          <button
            type='button'
            className='p-5  h-[35px] text-white  bg-everglade-800 hover:bg-everglade-400 rounded-md flex items-center justify-center'
            onClick={Forward}
          >
            Forward
          </button>
        )}
      </div>
    </div>
  )
}
