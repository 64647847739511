import { configureStore } from '@reduxjs/toolkit';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import thunk from 'redux-thunk';
import authReducer from './slices/authenticate';
import ChatPreSlice from "./slices/chat";
import notificationReducer from './slices/notifications';
import filterReducer from './slices/filter';
import passwordsReducer from './slices/passwords';
import patientSlice from './slices/patient';
import SidebarSlice from "./slices/sidebar";
import ChatHistory from "./slices/chatHistory";
import chatMessageSlice from './slices/chatMessages'


const persistConfig = {
    key: 'root',
    storage
}

const persistedReducer = persistReducer(persistConfig, authReducer)
const persistNoficationReducer = persistReducer(persistConfig, notificationReducer);
const persistFilterReducer = persistReducer(persistConfig, filterReducer);
const persistFilterHistory = persistReducer(persistConfig, ChatHistory);


export const store = configureStore({
    reducer: {
        auth: authReducer,
        pass: passwordsReducer,
        patient:patientSlice,
        sidebar:SidebarSlice,
        chatPre:ChatPreSlice,
        notification: persistNoficationReducer,
        filter:persistFilterReducer,
        chatHistory:persistFilterHistory,
        chatMessage:chatMessageSlice    
    },
    middleware: [thunk]
});

export const persistor = persistStore(store);
