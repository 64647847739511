import { formatDistance } from 'date-fns'
import Avatar from './avatar'
import { FaVideo } from 'react-icons/fa'
import { VscCallOutgoing } from 'react-icons/vsc'
import {
  MdFilePresent,
  MdArchive,
  MdOutlineAddIcCall,
  MdOutlineArrowCircleLeft,
  MdUnarchive,
  MdSchedule,
} from 'react-icons/md'

import { useState } from 'react'
import { toastModal, toastError } from '../../../utils/helpers/methods'
import { SENDBIRD_INFO } from '../../../utils/helpers/constant'
import removeDataOnAssignedChat from '../../../utils/services/archive'
import { GetPatientTag } from '../../../utils/services/patient'
import { Badge, Button, Modal, Popover } from 'flowbite-react'
import { useNavigate } from 'react-router-dom'
import { Icon } from '@iconify/react'
import { toogleFilter, unToogleFilter } from '../../../redux/slices/chat'
import { useDispatch } from 'react-redux'
import { locationUIInfo } from '../CityCode'

const ChanneLItemPreview = ({
  currentUser,
  item,
  onClick,
  isMoveToPractice,
  moveToPractice,
  activeTab,
  scheduledMessageList
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const gotToChatSpace = (e) => {
    dispatch(unToogleFilter())
    e.preventDefault()
    e.stopPropagation()
    e.nativeEvent.stopImmediatePropagation()
    onClick && onClick(item)
  }
  const [patientStatusInfo, setPatientStatusInfo] = useState()
  const [openModal, setOpenModal] = useState(false);

  const getPopupInfo = async(channel,e) =>{
    if (channel?.userId?.toLowerCase().startsWith('pa')) {
    const filteredPA = channel?.userId?.slice(2); 
    await GetPatientTag(filteredPA).then((response)=>{
    setPatientStatusInfo(response?.data?.data)});
    setOpenModal(true);
    }
  }

  const viewPatient = async ()=>{
    setOpenModal(false);
    setPatientStatusInfo(null)
    if(patientStatusInfo){
    navigate('/patientDetails/'+patientStatusInfo?.patient_id)
    }
  }

  const closePopup = (e)=>{
    e.preventDefault()
    e.stopPropagation()
    setOpenModal(false);
    setPatientStatusInfo(null)
  }

  const getChannelInfo = () => {
    const { members, name } = item
    switch (name) {
      case 'Admin':
        return { name: 'ConnectCare Team' }
      case 'Teams': {
        const filtered = members.filter((x) => x.userId !== currentUser?.userId)
        const memName = filtered.map((item, index) =>
          filtered.length - 1 === index ? item.nickname : item.nickname + ','
        )

        const status = filtered[0]?.connectionStatus
        return { name: memName[0], status: status }
      }
      case 'DPCDoctor':
        const filteredPA = members.filter((x) => x.userId.slice(0, 2) === 'PA')
        const memName = filteredPA.map((item, index) =>
          filteredPA.length - 1 === index ? item.nickname : item.nickname + ','
        )
        const memId = filteredPA.map((item, index) =>
        filteredPA.length - 1 === index ? item.userId : item.userId + ','
      )
        return { name: memName[0], status: filteredPA[0]?.connectionStatus ,userId:memId[0]}
      default:
        return { name: null }
    }
  }

  const onClickMoveToDefault = (e) => {
    e.preventDefault()
    e.stopPropagation()
    e.nativeEvent.stopImmediatePropagation()
    moveToPractice(item)
  }

  const channelInfo = getChannelInfo()

  const filterArray = item?.members?.filter(
    (member) => member?.metaData?.patient !== 'false'
  )
  const [showArchive, setShowArchive] = useState(true)

  const [showUnArchive, setShowUnArchive] = useState(false)


  const archiveChat = async (e) => {
    e.preventDefault()
    e.stopPropagation()
    if (activeTab == 'tab1' || activeTab == 'tab0') {
      removeDataOnAssignedChat(item,()=>{
        setShowArchive(false)
        setShowUnArchive(true)
        toastModal('Archived Successfully');
      }) 
    } else {
      var hideParams = {
        hidePreviousMessages: false,
        allowAutoUnhide: true,
      }
      await item.hide(hideParams).then((res) => {
        try {
          setShowArchive(false)
          setShowUnArchive(true)
          toastModal('Archived Successfully')
        } catch (error) {
          toastError(error)
        }
      })
    }
  }

  const unArchiveChat = async (e) => {
    e.preventDefault()
    e.stopPropagation()
    await item.unhide().then((res) => {
      try {
        setShowArchive(true)
        setShowUnArchive(false)
        toastModal('UnArchived Successfully')
      } catch (error) {
        toastError(error)
      }
    })
  }

  const toogleToFilter =()=>{
     dispatch(toogleFilter())
     onClick && onClick(item)
  }

  return (
    <ul
      role='list'
      className='divide-y  hover:bg-gray-200 flex justify-between items-center divide-gray-200 dark:divide-gray-700'
      >
      <li className='py-3 sm:py-4'>
        <div className='flex items-center space-x-4'>
          <div className='flex-shrink-0'>
            {filterArray.length === 0 ? (
              <Avatar
                onClick={(e)=>getPopupInfo(channelInfo,e)}
                showStatus={true}
                fname={channelInfo.name}
                lname=''
                image={
                  item?._name === 'Admin'
                    ? 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSC-Is9vPh4AgDCULO33AmoIpSvXcaJ51VrRZQUDXxl0YjDDAq94jciUm7zt7SB4OD5UhA&usqp=CAU'
                    : item?.coverUrl
                }
                status={channelInfo.status}
              />
            ) : (
              <Avatar
              showStatus={true}
              fname={
                !filterArray[0]?.plainProfileUrl
                  ? filterArray[0].nickname
                  : channelInfo.name
              }
              lname=''
              image={
                item?._name === 'Admin'
                  ? 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSC-Is9vPh4AgDCULO33AmoIpSvXcaJ51VrRZQUDXxl0YjDDAq94jciUm7zt7SB4OD5UhA&usqp=CAU'
                  : !filterArray[0]?.plainProfileUrl
                    ? ''
                    : filterArray[0]?.plainProfileUrl
              }
              status={channelInfo.status}
              onClick={(e) => getPopupInfo(channelInfo,e)}
            />              
            )}
          </div>
          <div className='flex-1 min-w-0' onClick={gotToChatSpace}>
            <p className='text-sm font-medium text-gray-900 truncate dark:text-white'>
              {channelInfo.name}
            </p>
            {/* <div className="flex gap-4">
              {item?.lastMessage?.customType === 'VIDEO_CALL' ? (
                item?.creator?.userId === currentUser?.userId ? <div className="flex items-center text-green-500">
                  <FaVideo className="mr-2" /> Call
                </div>:<p>{item?.creator?.nickname}</p>
              ) : (
                <p className="text-sm">
                  {((item?.lastMessage?.message)?.length > 100) ? `${(item?.lastMessage?.message)?.slice(0, 100) + '   ........'}` : item?.lastMessage?.message}·
                </p>
              )}

              <p className="text-sm text-gray-500 truncate dark:text-gray-400">
                {formatDistance(new Date(item?.lastMessage?.createdAt), new Date(), { addSuffix: true })}
              </p>
            </div> */}
            <div className='flex gap-4'>
              {/* {(item?.lastMessage?.customType === 'VIDEO_CALL' )&& (
                item?.creator?.userId === currentUser?.userId ? <div className="flex items-center text-green-500">
                  <FaVideo className="mr-2" /> Call
                </div>:<p>{item?.creator?.nickname}</p>
              )} */}
              {(item?.lastMessage?.messageType!=="file"&&item?.lastMessage?.customType!= "VIDEO_CALL") && <p className="text-sm">{((item?.lastMessage?.message)?.length > 100)?`${(item?.lastMessage?.message)?.slice(0,100)+'   ........'}`:item?.lastMessage?.message} </p>}
              {item?.lastMessage?.messageType=="file" && <p className="flex text-sm  text-gray-900 "><MdFilePresent className='m-1' />{item?.lastMessage?.name} </p>} 
              {item?.lastMessage?.customType== "VIDEO_CALL"&& <p className="flex text-sm text-everglade-900 "><VscCallOutgoing className='m-1'/>Call</p>} 
              <p className="text-sm text-gray-500 truncate dark:text-gray-400">
                {item?.lastMessage?.createdAt && formatDistance(new Date(item?.lastMessage?.createdAt), new Date(), { addSuffix: true })}
              </p>
              {item?.data && !isMoveToPractice && (
                <p className='text-sm text-orange-900 truncate dark:text-gray-400'>
                  Assigned to{' '}
                  {item.members.find((x) => x.userId == item.data).nickname}
                </p>
              )}
            </div>
          </div>
          
          {item.unreadMessageCount > 0 && (
            <div className='inline-flex items-center text-base font-semibold text-gray-900 dark:text-white'>
              <div className=' -top-2 -right-2 bg-red-600 text-white rounded-full px-2 py-1 text-xs'>
                {item?.unreadMessageCount}
              </div>
            </div>
          )}
        </div>
      </li>
      <div className='flex  flex-row items-center gap-5'>
        {isMoveToPractice && (
            <span
              onClick={onClickMoveToDefault}
              className='font-bold mr-2 inline-flex min-w-0 hover:cursor-pointer text-white bg-everglade-600 p-2'
            >
              {'Move to Practice channel'}{' '}
            </span>
          )}
      {activeTab == 'tab2' && (
        <MdUnarchive
          onClick={unArchiveChat}
          className={`text-everglade-600 cursor-pointer `}
          size={35}
        />
      )}
      {(activeTab == 'tab0' || activeTab == 'tab1') && (
        <MdArchive
          onClick={archiveChat}
          className={`text-everglade-600 cursor-pointer ${
            !showArchive && 'hidden'
          }`}
          size={35}
        />
      )}

         {scheduledMessageList.find(x=>x.channelUrl==item.url)  &&
         <MdSchedule     
          className={`text-everglade-600 cursor-pointer `}
          size={30}
          onClick={toogleToFilter}
        />}
        <Modal show={openModal} size="md" onClose={(e) => closePopup(e)} dismissible>
        <Modal.Header>Chat Info</Modal.Header>
        <Modal.Body>
            <div className="flex p-2 flex-row items-center">
              <Avatar
                className={"flex justify-center"}
                showStatus={true}
                fname={
                  !filterArray[0]?.plainProfileUrl
                    ? filterArray[0]?.nickname
                    : channelInfo?.name
                }
                lname=""
                image={
                  item?._name === "Admin"
                    ? "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSC-Is9vPh4AgDCULO33AmoIpSvXcaJ51VrRZQUDXxl0YjDDAq94jciUm7zt7SB4OD5UhA&usqp=CAU"
                    : !filterArray[0]?.plainProfileUrl
                      ? ""
                      : filterArray[0]?.plainProfileUrl
                }
                status={channelInfo.status}
                onClick={() => getPopupInfo(channelInfo)}
              />
              <h3 className="text-lg font-bold text-gray-500 dark:text-gray-400 ml-4">
                {patientStatusInfo &&
                  patientStatusInfo?.first_name + " " + patientStatusInfo?.last_name}
              </h3>
            </div>

            <div className='flex p-2 flex-row gap-4 '>
              {locationUIInfo(patientStatusInfo)}
              {patientStatusInfo?.patients?.is_vip == 1 && <Icon icon="quill:vip" fontSize={40} color='#ffbf00'></Icon>}
              {patientStatusInfo?.patients && <Badge className="ml-1" size={40} color={(patientStatusInfo?.patients.deleted_at == null || patientStatusInfo?.patients.deleted_at == '') ? 'success' : 'failure'}>{(patientStatusInfo?.patients.deleted_at == null || patientStatusInfo?.patients.deleted_at == '') ? 'Active' : 'InActive'}</Badge>}
              {patientStatusInfo?.location_group?.group_name && <Badge className="ml-1" color="blue" size={40}>{patientStatusInfo?.location_group?.group_name}</Badge>}
            </div>
            <div className="flex justify-center gap-4">
            <Button
            type="button"
            className="rounded-lg bg-blue-700 px-3 py-1.5 text-xs font-medium text-white hover:bg-blue-800 focus:outline-none focus:ring-4 focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
            onClick={() => viewPatient()}
          >
            View Details
          </Button>
             
            </div>
          
        </Modal.Body>
      </Modal>
      </div>
      
    </ul>
  )
}
export default ChanneLItemPreview
