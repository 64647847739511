export const CityCodes = {
    Nacogdoches:'Nac',
    Longview:'Lgw',
    Lufkin: "Luf",
    Tyler: "Tyr",
    Jacksonville: "jax",
    'Test Location1':"T1"
  };

export  const locationUIInfo = (Patientinfo) => {
  console.log('Patientinfo',Patientinfo)
    return (
      Patientinfo?.patients?.patientlocation?.length > 0 ?
        (Patientinfo?.patients?.patientlocation.map((e) => {
          return (
            <div className="bg-red-800 text-white rounded ml-1" >
              <span className=" text-white m-1 uppercase font-bold">{CityCodes[e.location_name]||e.location_name}</span>
            </div>
          )
        })) :
        (Patientinfo?.patients?.practice?.practice_location?.length > 0 ?
          (Patientinfo?.patients?.practice?.practice_location.map((e) => {
            return (<div className="bg-red-800 text-white rounded ml-1" >
              <span className=" text-white m-1 uppercase font-bold">{CityCodes[e.location_name] ||e.location_name}</span>
            </div>)
          }))
          : null))
  }