import { Menu } from '@headlessui/react'
// import { useChannelContext } from '@sendbird/uikit-react/Channel/context'
import { format } from 'date-fns'
import { saveAs } from 'file-saver'
import { useEffect, useState } from 'react'
import { CiClock2 } from 'react-icons/ci'
import {
  FaFileAlt,
  FaFileExcel,
  FaFilePdf,
  FaFileWord,
  FaPhoneAlt,
} from 'react-icons/fa'
import { HiDotsVertical } from 'react-icons/hi'
import { LiaCheckDoubleSolid } from 'react-icons/lia'
import { LuForward } from 'react-icons/lu'
import { MdOutlineCheck } from 'react-icons/md'
import { useSelector } from 'react-redux'
import { SENDBIRD_INFO } from '../../../utils/helpers/constant'
import { getFileExtension } from '../input/fileUtils'
import Modal from '../modal'
import Avatar from './avatar'
import EditMessage from './editMessage'
import { toastModal } from '../../../utils/helpers/methods'

export const CustomChat = ({
  user,
  message,
  channel,
  formatMessageTime,
  setMessage,
  setMessageForwardedFrom,
  addToMyChat,
  retreiveScheduleMessage,
  setAllMessages,
  getAllMessages,
}) => {
  // const { currentGroupChannel } = useChannelContext()
  const { doctorDetails } = useSelector((state) => state.auth)

  const [read, setRead] = useState(false)
  const [delivered, setDelivered] = useState(false)
  const [messageData, setMessageData] = useState()
  const [editMessage, setEditMessage] = useState(false)
  const [messageToEdit, setMessageToEdit] = useState('')

  useEffect(() => {
    if (message?.data) {
      const data = JSON.parse(message.data)
      setMessageData(data)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const SaveFile = (fileUrl, fileName) => {
    saveAs(fileUrl, fileName)
  }

  function saveImage(url) {
    saveAs(url, 'img')
  }

  window.handleDownload = (url) => {
    return saveImage(url)
  }

  const createMarkup = (text) => {
    return { __html: text }
  }

  const messageFormat = (message, color, scheduledAt) => {
    if (!message) return null
    const urlRegex = /(https?:\/\/[^\s]+)/g

    const replaceURLsWithLinks = (text) => {
      let formattedMessage = text.replace(urlRegex, (url) => {
        const decodedURL = decodeURIComponent(url)

        if (/\.(jpg|jpeg|png)$/i.test(decodedURL)) {
          return `<img src="${decodedURL}" alt="file" onclick="return handleDownload('${decodedURL}')" />`
        }
        if (/\.(docx|pdf|xls)$/i.test(decodedURL)) {
          return `<a href="${decodedURL}" target="download">
          <p class="${
            color ? 'bg-everglade-600 text-white' : 'bg-[#F5F5F5] text-black'
          } rounded-md mt-2 p-2 text-center">
            Download
          </p>
        </a>`
        } else {
          return `<a href="${decodedURL}" class="underline"> ${
            decodedURL?.length > 70
              ? decodedURL.slice(0, 90) + '....'
              : decodedURL
          } </a>`
        }
      })

      if (scheduledAt) {
        formattedMessage += `<br><i>Scheduled at ${scheduledAt}</i>`
      }

      return formattedMessage
    }
    return (
      <div
        className={`${
          color ? 'text-black' : ' text-white'
        } rounded-md max-w-[550px]`}
      >
        <span
          dangerouslySetInnerHTML={createMarkup(
            replaceURLsWithLinks(message).replace(/\n/g, '<br>')
          )}
        />
      </div>
    )
  }

  const ogMetaDataFormat = (data, color, scheduledAt = null) => {
    return (
      <>
        <div
          className={`${
            color ? ' text-black ' : ' text-white  '
          } p-2 rounded-md max-w-[550px]`}
        >
          {data.ogMetaData && (
            <div
              className={`${
                color ? ' text-black ' : ' text-white  '
              } p-2 flex flex-row justify-between`}
            >
              {data.ogMetaData?.defaultImage?.url && (
                <div className='w-40'>
                  <a
                    className={`${
                      color ? 'text-black ' : 'bg-everglade-600  text-white  '
                    } rounded-md m-4 max-w-[550px]`}
                    href={data.ogMetaData.url}
                  >
                    <img
                      src={data.ogMetaData?.defaultImage?.url}
                      alt={data.ogMetaData?.title}
                    />
                  </a>
                </div>
              )}
              <div className='flex flex-col justify-evenly '>
                <h1 className='font-extrabold'>{data.ogMetaData?.title}</h1>
                <p>{data.ogMetaData?.description}</p>
                {messageFormat(data?.message, color, scheduledAt)}
              </div>
            </div>
          )}
        </div>
      </>
    )
  }
  const dislayPredefinedData = (color) => {
    return (
      <div
        className={`${
          color ? '  text-black ' : ' text-white checkme'
        } flex flex-col max-w-[550px]`}
      >
        {messageData?.file?.map((e, i) => {
          const ext = getFileExtension(e.filename)
          let format = {
            url: doctorDetails?.data?.replacement_url + '/' + e.filename,
            name: e.filename,
          }
          return (
            <div className='p-1' id={i}>
              {dislayFiles(format, 'file', ext)}
            </div>
          )
        })}

        {messageData?.url?.length > 0 && (
          <>
            <p className='font-bold'>Link : </p>
            {messageData?.url?.map((e) => {
              return messageFormat(e, color)
              // <a className='underline' href={e}>{' '+ e}</a>
            })}
          </>
        )}
        {/* <p>{messageData?.message}</p> */}
      </div>
    )
  }

  const formatDuration = (stamp) => {
    if (
      stamp.callStatus.toLowerCase() === 'declined' ||
      stamp.callStatus.toLowerCase() === 'missed' ||
      stamp.callStatus.toLowerCase() === 'canceled'
    ) {
      return (
        <div className='flex flex-row gap-2'>
          <p>{stamp.callStatus}</p>
          {stamp?.time ? format(new Date(stamp?.time), 'hh:mm a') : ''}
        </div>
      )
    } else {
      // const durationMillis = parseInt(stamp?.duration)
      // const durationSeconds = Math.round(durationMillis / 1000)

      // const hours = Math.floor(durationSeconds / 3600)
      // const minutes = Math.floor((durationSeconds % 3600) / 60)
      // const seconds = durationSeconds % 60
      const hours = Math.round((stamp?.duration / (1000 * 60 * 60)) % 24)
      const minutes = Math.round((stamp.duration / (1000 * 60)) % 60)
      const seconds = Math.round((stamp.duration / 1000) % 60)
      return (
        <div className='flex flex-row'>
          <p>CALL ENDED</p>
          <div className='flex flex-row justify-evenly items-center gap-2'>
            <p className='pl-1'>
              {hours > 0 ? `${hours}h ` : ''}
              {minutes > 0 ? `${minutes}m ` : ''}
              {seconds > 0 ? `${seconds}s` : ''}
            </p>
            {format(new Date(stamp?.time), 'hh:mm a')}
          </div>
        </div>
      )
    }
  }

  const dislayFiles = (message, messageType, type, color, scheduledInfo) => {
    if (messageType === 'file') {
      switch (type) {
        case 'png':
        case 'jpeg':
        case 'gif':
        case 'jpg':
        case 'bmp':
        case 'tiff':
        case 'webp':
        case 'image/png':
        case 'image/jpeg':
        case 'image/gif':
        case 'image/jpg':
        case 'image/x-ms-bmp':
        case 'image/bmp':
        case 'image/tiff':
        case 'image/svg+xml':
        case 'image/svg':
        case 'svg':
        case 'image/avif':
          return (
            <img
              src={message?.url}
              alt='File'
              className='w-[200px] h-[200px] object-cover hover:cursor-pointer'
              onClick={(e) => {
                e.preventDefault()
                SaveFile(message?.url, message?.name)
              }}
            />
          )
        case 'pdf':
        case 'application/pdf':
          return (
            <div
              className={`flex flex-col ${
                color ? 'text-white' : 'text-black'
              }  p-2 cursor-pointer`}
              onClick={(e) => {
                e.preventDefault()
                SaveFile(message?.url, message?.name)
              }}
            >
              <div
                className={`${
                  color ? 'text-white' : 'text-black'
                } flex flex-row items-center rounded-lg p-2`}
              >
                <FaFilePdf
                  size={30}
                  className={` ${
                    color
                    ? '  bg-[#F5F5F5] text-gray-700'
                    : ' bg-everglade-600  text-white '
                    }  `}
                />
                <div className={`${
                    color
                    ? '  bg-[#F5F5F5] text-gray-700 '
                    : ' bg-everglade-600  text-white '
                    } ml-2`}> {message?.name} </div>
              </div>
            </div>
          )
        case 'application/vnd.ms-excel':
          return (
            <div
              className={`flex items-center  ${
                color
                  ? '  bg-[#F5F5F5] text-black '
                  : ' bg-everglade-600  text-white '
              }  p-2 cursor-pointer`}
              onClick={(e) => {
                e.preventDefault()
                SaveFile(message?.url, message?.name)
              }}
            >
              <div
                className={`${
                  color
                    ? '  bg-everglade-600  text-white'
                    : ' bg-[#F5F5F5] text-black '
                } flex flex-row items-center rounded-lg p-2`}
              >
                <FaFileExcel
                  size={30}
                  className={` ${
                    color ? 'text-white' : ' text-green-500   '
                  }  `}
                />
                <div className='ml-2'> {message?.name} </div>
              </div>
            </div>
          )
        case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
          return (
            <div
              className={`flex items-center  ${
                color
                  ? '  bg-[#F5F5F5] text-black '
                  : ' bg-everglade-600  text-white '
              } border p-2 cursor-pointer`}
              onClick={(e) => {
                e.preventDefault()
                SaveFile(message?.url, message?.name)
              }}
            >
              <div
                className={`${
                  color
                    ? '  bg-everglade-600  text-white'
                    : ' bg-[#F5F5F5] text-black '
                } flex flex-row items-center rounded-lg p-2`}
              >
                <FaFileExcel
                  size={30}
                  className={` ${
                    color ? 'text-white' : ' text-green-500   '
                  }  `}
                />
                <div className='ml-2'> {message?.name} </div>
              </div>
            </div>
          )
        case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
        case 'docx':
        case 'doc':
          return (
            <div
              className={`flex items-center  ${
                color
                  ? '  bg-[#F5F5F5] text-black '
                  : ' bg-everglade-600  text-white '
              }  p-2 cursor-pointer`}
              onClick={(e) => {
                e.preventDefault()
                SaveFile(message?.url, message?.name)
              }}
            >
              <div
                className={`${
                  color
                    ? '  bg-everglade-600  text-white'
                    : ' bg-[#F5F5F5] text-black '
                } flex flex-row items-center rounded-lg p-2`}
              >
                <FaFileWord
                  size={30}
                  className={` ${
                    color ? 'text-white' : ' text-green-500   '
                  }  `}
                />
                <div className=''> {message?.name} </div>
              </div>
            </div>
          )
        case 'text/plain':
          return (
            <div
              className={`flex items-center  ${
                color
                  ? '  bg-[#F5F5F5] text-black '
                  : ' bg-everglade-600  text-white '
              }  p-2 cursor-pointer`}
              onClick={(e) => {
                e.preventDefault()
                SaveFile(message?.url, message?.name)
              }}
            >
              <div
                className={`${
                  color
                    ? '  bg-everglade-600  text-white'
                    : ' bg-[#F5F5F5] text-black '
                } flex flex-row items-center rounded-lg p-2`}
              >
                <FaFileAlt
                  size={30}
                  className={` ${
                    color ? 'text-white' : ' text-green-500   '
                  }  `}
                />
                <div className=''> {message?.name} </div>
              </div>
            </div>
          )
        default:
          if (type.startsWith('image/')) {
            return (
              <img
                src={message?.url}
                alt='File'
                className='w-[200px] h-[200px] object-cover hover:cursor-pointer'
                onClick={(e) => {
                  e.preventDefault()
                  SaveFile(message?.url, message?.name)
                }}
              />
            )
          } else {
            return
          }
      }
    } else {
      if (message?.ogMetaData != null) {
        if (scheduledInfo) {
          return ogMetaDataFormat(
            message,
            color,
            formatMessageTime(scheduledInfo?.scheduledAt, true)
          )
        } else {
          return ogMetaDataFormat(message, color)
        }
      } else {
        if (scheduledInfo) {
          return messageFormat(
            message?.message,
            color,
            formatMessageTime(scheduledInfo?.scheduledAt, true)
          )
        } else {
          return messageFormat(message?.message, color)
        }
      }
    }
  }

  const deleteMyChat = async () => {
    try {
      const baseUrl = `https://api-${SENDBIRD_INFO.appId}.sendbird.com/v3/group_channels/${channel?.url}`
      const isScheduled = message._scheduledStatus
      const endpoint = isScheduled
        ? `scheduled_messages/${message.scheduledInfo.scheduledMessageId}`
        : `messages/${message.messageId}`

      const config = {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'Api-Token': SENDBIRD_INFO.sendBirdToken,
        },
      }
      const response = await fetch(`${baseUrl}/${endpoint}`, config)
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`)
      }
      if (isScheduled) {
        setAllMessages((prev) =>
          prev.filter(
            (msg) =>
              msg.scheduledInfo.scheduledMessageId !==
              message.scheduledInfo.scheduledMessageId
          )
        )
        await retreiveScheduleMessage()
      } else {
        setAllMessages((prev) =>
          prev.filter((msg) => msg.messageId !== message.messageId)
        )
        await getAllMessages()
      }

      return true
    } catch (error) {
      console.error('Failed to delete message:', error)
    }
  }

  const handleMessageDeletion = async () => {
    try {
      await deleteMyChat()
    } catch (error) {
      console.error('Failed to delete message:', error)
    }
  }

  const sendNowMessage = async () => {
    try {
      const baseUrl = `https://api-${SENDBIRD_INFO.appId}.sendbird.com/v3/group_channels/${channel?.url}/scheduled_messages/${message.scheduledInfo.scheduledMessageId}/send_now`
      const config = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Api-Token': SENDBIRD_INFO.sendBirdToken,
        },
        body: JSON.stringify({}),
      }
      const response = await fetch(`${baseUrl}`, config)
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`)
      }
      toastModal('Schedule message sent  now  !!')
      setAllMessages((prev) =>
        prev.filter(
          (msg) =>
            msg.scheduledInfo.scheduledMessageId !==
            message.scheduledInfo.scheduledMessageId
        )
      )

      await retreiveScheduleMessage()
      return true
    } catch (error) {
      console.error('Failed to send message:', error)
    }
  }

  return (
    <div className='mt-5 mb-5'>
      {user !== message?.sender?.userId ? (
        <>
          {message.customType === 'VIDEO_CALL' ? (
            <div className='flex justify-center '>
              <div className='flex flex-row justify-evenly items-center bg-everglade-500  text-center h-15 px-10  w-[300px] rounded-2xl text-white'>
                <FaPhoneAlt />
                <div className='ml-2'>
                  {message?.sender?.nickname} {message?.callStatus}
                  {formatDuration(JSON.parse(message?.message))}
                </div>
              </div>
            </div>
          ) : (
            <>
              {messageData?.name && (
                <span className='flex flex-row italic  text-gray-400'>
                  <LuForward />
                  Forwarded{' '}
                  {message?.sender?.nickname != messageData?.name
                    ? messageData?.name + "'s message"
                    : null}
                </span>
              )}
              <div className='flex  flex-row  gap-5 '>
                {channel.name === 'Admin' ? (
                  <Avatar
                    image={
                      'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSC-Is9vPh4AgDCULO33AmoIpSvXcaJ51VrRZQUDXxl0YjDDAq94jciUm7zt7SB4OD5UhA&usqp=CAU'
                    }
                  />
                ) : (
                  <Avatar
                    fname={message?.sender?.nickname}
                    image={
                      message?.sender?.plainProfileUrl !== null
                        ? message?.sender?.plainProfileUrl
                        : null
                    }
                    width={'w-9 h-9'}
                    profile={true}
                  />
                )}
                <div className='flex flex-col'>
                  {channel.name === 'Admin' ? (
                    <p className=' italic  text-gray-400'>ConnectCare Team</p>
                  ) : (
                    <p className=' italic  text-gray-400'>
                      {message?.sender?.nickname}
                    </p>
                  )}
                  {/* <div className='rounded-md max-w-[formatDuration550px]'>
            {
            messageData?.name && <span className='flex flex-row italic p-1 text-gray-400'>
              <LuForward />
               Forwarded {message?.message?.sender?.nickname!=(messageData?.name)?(messageData?.name+"'s message"):null}
            </span>
           } */}
                  <div
                    className={`${
                      message?.sender?.userId.startsWith('PA')
                        ? ' bg-[#99422b] text-white'
                        : 'bg-[#F5F5F5] text-black '
                    }  cursor-pointer rounded-lg p-2`}
                  >
                    {dislayFiles(
                      message?.customType ? null : message,
                      message?.messageType,
                      message?.type,
                      message?.sender?.userId.startsWith('PA') ? null : 'color'
                    )}
                    <div
                      className={`${
                        message?.sender?.userId.startsWith('PA')
                          ? ' text-white '
                          : ' text-gray-500  dark:text-gray-400 '
                      } text-sm self-end`}
                    >
                      <div className='flex flex-row gap-4'>
                        {messageData && dislayPredefinedData(true)}
                        <p className='flex flex-row items-end gap-4 ml-auto text-xs'>
                          {formatMessageTime(message?.createdAt, true)}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                {/* </div> */}
                <div className='relative'>
                  <Menu as={'div'} className={'inline-block text-left '}>
                    <Menu.Button>
                      {message?.messageType !== 'file' &&
                        message?.sender &&
                        message?.sender?.userId !== '' &&
                        message?.sender?.userId.startsWith('PA') && (
                          <div>
                            <HiDotsVertical />
                          </div>
                        )}
                    </Menu.Button>
                    <Menu.Items
                      className=' absolute  z-10 w-fit left-10 top-[-25px]  mt-2 origin-top-right bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none'
                      style={{
                        zIndex: 5,
                      }}
                    >
                      <div className='px-1 py-1 flex flex-col '>
                        {message?.messageType !== 'file' && (
                          <Menu.Item>
                            {({ active }) => (
                              <p
                                className={`${
                                  active && 'text-everglade-700  cursor-pointer'
                                } p-2`}
                                onClick={() => {
                                  setMessage(message?.message)
                                  setMessageForwardedFrom(
                                    message?.sender?.nickname
                                  )
                                }}
                              >
                                Forward
                              </p>
                            )}
                          </Menu.Item>
                        )}
                        {message?.sender &&
                          message?.sender?.userId !== '' &&
                          message?.sender?.userId.startsWith('PA') && (
                            <Menu.Item>
                              {({ active }) => (
                                <p
                                  className={`${
                                    active &&
                                    'text-everglade-700  cursor-pointer'
                                  } p-2`}
                                  onClick={() => {
                                    addToMyChat(message)
                                  }}
                                >
                                  Assign
                                </p>
                              )}
                            </Menu.Item>
                          )}
                      </div>
                    </Menu.Items>
                  </Menu>
                </div>
              </div>
            </>
          )}
        </>
      ) : message.customType === 'VIDEO_CALL' ? (
        <div className='flex justify-center '>
          <div className='flex flex-row justify-evenly items-center bg-everglade-500  text-center h-15 px-10  w-[300px] rounded-2xl text-white'>
            <FaPhoneAlt />
            <div className='ml-2'>
              {message?.sender?.nickname}
              {formatDuration(JSON.parse(message?.message))}
            </div>
          </div>
        </div>
      ) : (
        <div className='flex justify-end'>
          <div className='flex flex-col'>
            {messageData?.name && (
              <span className='flex flex-row italic  text-gray-400'>
                <LuForward />
                Forwarded{' '}
                {message?.sender?.nickname != messageData?.name
                  ? messageData?.name + "'s message"
                  : null}
              </span>
            )}
            <div className='flex flex-row gap-5'>
              <div className='relative self-end'>
                <Menu as={'div'} className={'inline-block text-left pb-2'}>
                  <Menu.Button>
                    <div>
                      <HiDotsVertical />
                    </div>
                  </Menu.Button>
                  <Menu.Items
                    className=' absolute  z-10  right-[25px] top-[-30px]  w-fit mt-2  origin-top-right bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none'
                    style={{
                      zIndex: 5,
                    }}
                  >
                    <div className='px-1 py-1 flex flex-col '>
                      {!message._scheduledStatus &&
                        message?.messageType !== 'file' && (
                          <Menu.Item>
                            {({ active }) => (
                              <p
                                className={`${
                                  active && 'text-everglade-700  cursor-pointer'
                                } p-2`}
                                onClick={() => {
                                  setMessage(message?.message)
                                  setMessageForwardedFrom(
                                    message?.sender?.nickname
                                  )
                                }}
                              >
                                Forward
                              </p>
                            )}
                          </Menu.Item>
                        )}
                      {!message._scheduledStatus &&
                        message?.sender &&
                        message?.sender?.userId !== '' &&
                        message?.sender?.userId.startsWith('PA') && (
                          <Menu.Item>
                            {({ active }) => (
                              <p
                                className={`${
                                  active && 'text-everglade-700  cursor-pointer'
                                } p-2`}
                                onClick={() => {
                                  addToMyChat(message)
                                }}
                              >
                                Assign
                              </p>
                            )}
                          </Menu.Item>
                        )}
                      {
                        <Menu.Item>
                          {({ active }) => (
                            <p
                              className={`${
                                active && 'text-everglade-700  cursor-pointer'
                              } p-2`}
                              onClick={handleMessageDeletion}
                            >
                              Delete
                            </p>
                          )}
                        </Menu.Item>
                      }
                      <Menu.Item>
                        {({ active }) => (
                          <p
                            className={`${
                              active && 'text-everglade-700  cursor-pointer'
                            } p-2`}
                            onClick={() => {
                              setEditMessage(true)
                              setMessageToEdit(message.message)
                            }}
                          >
                            Edit
                          </p>
                        )}
                      </Menu.Item>
                      {message._scheduledStatus && (
                        <Menu.Item>
                          {({ active }) => (
                            <p
                              className={`${
                                active && 'text-everglade-700  cursor-pointer'
                              } p-2`}
                              onClick={sendNowMessage}
                            >
                              Send
                              <span className='pl-1'>now </span>
                            </p>
                          )}
                        </Menu.Item>
                      )}
                    </div>
                  </Menu.Items>
                </Menu>
              </div>
              <div className='bg-everglade-600 text-white cursor-pointer rounded-lg p-2'>
                {dislayFiles(
                  message,
                  message?.messageType,
                  message?.type,
                  false,
                  message?.scheduledInfo
                )}
                <div className='text-sm text-gray-300  dark:text-gray-300 self-end'>
                  <div className='flex flex-row gap-4'>
                    {messageData && dislayPredefinedData(false)}
                    {/* <p className='flex flex-row items-end gap-4 ml-auto'>
                      {formatMessageTime(message?.createdAt, false)}
                    </p> */}
                    <p className='flex flex-row items-end gap-4 ml-auto text-gray-200 text-xs'>
                          {formatMessageTime(message?.createdAt, true)}
                        </p>
                  </div>
                </div>
              </div>
              <div className='text-sm text-gray-500  dark:text-gray-400 self-end'>
                <div className='flex flex-row items-center gap-4'>
                  {message._scheduledStatus ? (
                    <CiClock2 size={20} className='text-everglade-500' />
                  ) : delivered && !read ? (
                    <MdOutlineCheck />
                  ) : (
                    <LiaCheckDoubleSolid
                      className={read && ' text-everglade-500'}
                    />
                  )}
                </div>
              </div>
              {editMessage && (
                <Modal
                  title={'Edit Message'}
                  close={() => {
                    setEditMessage(false)
                  }}
                >
                  <EditMessage
                    message={messageToEdit}
                    wholeMessage={message}
                    channel={channel}
                    retreiveScheduleMessage={retreiveScheduleMessage}
                    setAllMessages={setAllMessages}
                    messageStatus={message._scheduledStatus}
                    getAllMessages={getAllMessages}
                    doctorDetails={doctorDetails}
                    onClose={() => {
                      setEditMessage(false)
                    }}
                  />
                </Modal>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
