import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    uplaodedImage: false
};

const chatMessagesSlice = createSlice({
    name: "triggerMessage",
    initialState,
    reducers: {
        setUploaded: (state) => {
            state.uplaodedImage=true
        },
        setUploadedFalse: (state) => {
            state.uplaodedImage=false}
    }
});

export const { setUploaded ,setUploadedFalse} = chatMessagesSlice.actions;

export default chatMessagesSlice.reducer;
