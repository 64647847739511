import { Icon } from '@iconify/react'
import { ScheduledStatus } from '@sendbird/chat/groupChannel'
import {
  sendBirdSelectors,
  useSendbirdStateContext,
} from '@sendbird/uikit-react'
import { Badge } from 'flowbite-react'
import { Form, Formik } from 'formik'
import { useEffect, useMemo, useRef, useState } from 'react'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { AiOutlineSend } from 'react-icons/ai'
import { IoMdAttach, IoMdCloseCircle } from 'react-icons/io'
import {
  MdArchive,
  MdLink,
  MdOutlineAddIcCall,
  MdOutlineArrowCircleLeft,
  MdScheduleSend,
  MdUnarchive,
} from 'react-icons/md'
import { useDispatch, useSelector } from 'react-redux'
import SendBirdCall from 'sendbird-calls'
import '../../../customStyle/custom-sendbird.css'
import { DoctorDetailsRequest } from '../../../redux/actions/authActions'
import {
  saveCallDuration,
  SaveChannelMessage,
  saveChatAssign,
  UpdateChannelLatestMessage,
} from '../../../redux/actions/chat'
import { getPatientList } from '../../../redux/actions/passActions'
import { toogleUplaod } from '../../../redux/slices/chat'
import { SENDBIRD_INFO } from '../../../utils/helpers/constant'
import { toastError, toastModal } from '../../../utils/helpers/methods'
import removeDataOnAssignedChat from '../../../utils/services/archive'
import { GetPatientTag } from '../../../utils/services/patient'
import ForwardMessageModal from '../../ForwardMessageModal'
import ChatCall from '../calls'
import { InputTextArea } from '../input'
import { getFileIcon } from '../input/fileUtils'
import Modal from '../modal'
import { CustomChat } from './customChat'
import CustomTimePicker from './customTimePicker'
import { locationUIInfo } from '../CityCode'

const ChatUI = ({
  activeTab,
  channel,
  onSendMessage,
  close,
  currentUser,
  selectedChat,
}) => {
  const store = useSendbirdStateContext()
  const inputRef = useRef('')
  const shechRef = useRef('')
  const messagesEndRef = useRef(null)
  const messagesContainerRef = useRef(null)

  const scrollToBottom = () => {
    if (messagesContainerRef.current) {
      messagesContainerRef.current.scrollTop =
        messagesContainerRef.current.scrollHeight
    }
  }

  const { userInfo, doctorDetails, userToken } = useSelector(
    (state) => state.auth
  )
  const { open } = useSelector((state) => state.sidebar)
  const { filterScheduled } = useSelector((state) => state.chatPre)
  const { uplaodedImage } = useSelector((state) => state.chatMessage)


  const token = userInfo.doctor_login.sendbird_user_id
  const dispatch = useDispatch()
  const [showCall, setShowCall] = useState(false)
  const [callInstance, setCallInstance] = useState()
  const [mute, setMute] = useState(false)
  const [connected, setConnected] = useState(false)
  const [showCallIcon, setShowCallIcon] = useState(false)
  const [text, setText] = useState('')
  const [showShortcuts, setshowShortCuts] = useState(false)
  const [shortCuts, setShortcuts] = useState([])
  const [showArchive, setShowArchive] = useState(true)
  const [showUnArchive, setShowUnArchive] = useState(false)
  const [user, setUser] = useState('')
  const [message, setMessage] = useState('')
  const [messageForwardedFrom, setMessageForwardedFrom] = useState('')
  const [patientList, setPatientList] = useState([])
  const [forwardId, setForwardId] = useState('')
  const [messageId, setMessageId] = useState('')
  const [isTeam, setIsTeam] = useState('')
  const { PatientList, PatientListinfo } = useSelector((state) => state.pass)
  const [openModal, setOpenModal] = useState(false)
  const [time, setTime] = useState('')
  const [startDate, setStartDate] = useState(new Date())
  const [schText, setschText] = useState('')
  const [showScheShortcuts, setshowScheShortCuts] = useState(false)
  const [schShortCuts, setSchShortcuts] = useState([])

  var sdk = store?.stores.sdkStore.sdk

  const [allMessages, setAllMessages] = useState([])

  function removeDuplicateMessages(messages) {
    const uniqueMessages = []
    const seenMessages = new Set()

    messages.forEach((message) => {
      const signature = `${message.message}-${message.sender.userId}-${
        message.scheduledInfo?.scheduledAt || ''
      }`
      if (!seenMessages.has(signature)) {
        seenMessages.add(signature)
        uniqueMessages.push(message)
      }
    })

    return uniqueMessages
  }

  async function retreiveScheduleMessage(newMessage) {
    if (newMessage) return

    if (!sdk?.groupChannel?.createScheduledMessageListQuery || !channel?.url) {
      console.warn('SDK or channel is not properly initialized.')
      return
    }

    try {
      const params = {
        channelUrl: channel.url,
        scheduledStatus: [ScheduledStatus.PENDING],
      }

      const scheduledMessageListQuery =
        sdk.groupChannel.createScheduledMessageListQuery(params)

      if (!scheduledMessageListQuery) {
        console.warn('Failed to create scheduled message list query.')
        return
      }

      const queriedScheduledMessages = await scheduledMessageListQuery.next()

      setAllMessages((prev) => {
        const combinedMessages = [...prev, ...queriedScheduledMessages]
        return removeDuplicateMessages(combinedMessages)
      })
    } catch (error) {
      console.error('Error retrieving scheduled messages:', error)
    }
  }

  async function getAllMessages() {
    const params = {
      limit: 20,
      reverse: false,
      includeReactions: true,
    }
    const query = channel?.createPreviousMessageListQuery(params)
    try {
      const messages = await query?.load()
      setAllMessages((prev) => [...prev, ...messages])
    } catch (e) {}
  }

  useEffect(() => {
    scrollToBottom()
    // eslint-disable-next-line no-use-before-define
  }, [allMessages])

  useEffect(() => {
    if (channel && !filterScheduled) {
      getAllMessages()
    } else {
      if (sdk) {
        retreiveScheduleMessage()
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [channel, filterScheduled, sdk])

  useEffect(()=>{
    if( channel &&  uplaodedImage){
      getAllMessages()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[uplaodedImage,channel])

  const filteredMessages = useMemo(() => {
    const messagesMap = new Map()

    allMessages.forEach((msg) => {
      const id = msg.messageId || msg.scheduledInfo?.scheduledMessageId
      messagesMap.set(id, msg)
    })

    return Array.from(messagesMap.values()).sort(
      (a, b) => new Date(a.createdAt) - new Date(b.createdAt)
    )
  }, [allMessages])

  const [selectedShortCut, setSelectedShortCut] = useState()
  const [selectedShortCutFiles, setSelectedShortCutFiles] = useState([])
  const [selectedShortCutLinks, setSelectedShortCutLinks] = useState([])
  const [patientStatusInfo, setPatientStatusInfo] = useState()
  const [schSelectedShortCutFiles, setSChSelectedShortCutFiles] = useState([])
  const [schSelectedShortCutLinks, setSchSelectedShortCutLinks] = useState([])


  const handleGetDoctorDetails = () => {
    dispatch(DoctorDetailsRequest({ id: userInfo?.id }))
  }
  const getpatientStatusInfo = async () => {
    const filteredPA = channel.members.filter(
      (x) => x.userId.slice(0, 2) === 'PA'
    )
    const id = filteredPA[0].userId.slice(2)
    await GetPatientTag(id).then((response) => {
      setPatientStatusInfo(response?.data?.data)
    })
  }

  useEffect(() => {
    if (channel.customType == 'DPCDoctor') {
      getpatientStatusInfo()
    }
    let obj = {
      token: userToken,
      practitioner_id: userInfo.id,
      employer_filter: '',
      patient_tag: '',
      search_key: '',
      limit: 5,
      patient_login: '1',
    }
    dispatch(getPatientList(obj))
  }, [])

  useEffect(() => {
    if (PatientList) {
      let newList = PatientList.filter((item) => item.patient_login !== null)
      setPatientList(newList)
    }
  }, [PatientList])

  useEffect(() => {
    const pa = channel.members.find((item) => item.userId.slice(0, 2) === 'PA')
    if (channel.isHidden) {
      setShowArchive(false)
      setShowUnArchive(true)
    } else {
      setShowArchive(true)
      setShowUnArchive(false)
    }
    if (pa && pa?.userId) {
      setShowCallIcon(true)
    }
    //eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (currentUser) {
      setUser(currentUser?.userInfo?.doctor_login?.sendbird_user_id)
    }
  }, [currentUser])

  useEffect(() => {
    handleGetDoctorDetails()
    //eslint-disable-next-line
  }, [])

  useEffect(()=>{
    (async()=>{
      await channel?.markAsRead();
    })()
    //eslint-disable-next-line
  },[])



  const archiveChat = async () => {
    if (activeTab == 'tab1' || activeTab == 'tab0') {
      removeDataOnAssignedChat(channel, () => {
        setShowArchive(false)
        setShowUnArchive(true)
        toastModal('Archived Successfully')
      }) //on archiving from assigned chat  need to remove assigned label for all
    } else {
      var hideParams = {
        hidePreviousMessages: false,
        allowAutoUnhide: true,
      }
      await channel.hide(hideParams).then((res) => {
        try {
          setShowArchive(false)
          setShowUnArchive(true)
          toastModal('Archived Successfully')
        } catch (error) {
          toastError(error)
        }
      })
    }
  }

  const unArchiveChat = async () => {
    await channel.unhide().then((res) => {
      try {
        setShowArchive(true)
        setShowUnArchive(false)
        toastModal('UnArchived Successfully')
      } catch (error) {
        toastError(error)
      }
    })
  }

  const initializeCall = async (isVideoCall) => {
    SendBirdCall.authenticate({ userId: token }, (result, error) => {
      if (error) {
      } else {
        /* Succeeded to connect*/
        const callerId = token
        setShowCall(true)
        if (callerId && showCallIcon) {
          const calleeId = channel.members.find(
            (item) => item.userId.slice(0, 2) === 'PA'
          )
          const dialParams = {
            userId: calleeId?.userId,
            isVideoCall: true,
            callOption: {
              localMediaView: document.getElementById('local_video_element_id'),
              remoteMediaView: document.getElementById(
                'remote_video_element_id'
              ),
              audioEnabled: true,
              videoEnabled: true,
            },
            sendBirdChatOptions: {
              channel,
            },
          }
          const call = SendBirdCall.dial(dialParams, (call, error) => {
            if (error) {
              // Setting media viewers lazily.
            }
            call.setLocalMediaView(
              document.getElementById('local_video_element_id')
            )
            call.setRemoteMediaView(
              document.getElementById('remote_video_element_id')
            )
          })

          setCallInstance(call)

          call.onFailed = (Call) => {
            toastError('Call Failed')
            setShowCall(false)
            setConnected(false)
          }
          call.onEstablished = (call) => {}
          call.onConnected = (call) => {
            setShowCall(true)
            setConnected(true)
          }
          call.onEnded = (callEndResult) => {
            console.log('ce->', { callEndResult })
            setShowCall(false)
            setConnected(false)
            const sendUserMessage = sendBirdSelectors.getSendUserMessage(store)
            const obj = {
              duration: callEndResult.callLog.duration,
              time: callEndResult.callLog?.startedAt,
              callStatus: callEndResult.endResult.toUpperCase(),
            }
            const endTime = callEndResult._startedAt + call.getDuration()
            const durationMillis = call.getDuration()
            const durationSeconds = Math.round(durationMillis / 1000)

            const hours = Math.floor(durationSeconds / 3600)
            const minutes = Math.floor((durationSeconds % 3600) / 60)
            const seconds = durationSeconds % 60
            const duration = `${hours > 0 ? `${hours}h ` : ''}${
              minutes > 0 ? `${minutes}m ` : ''
            }${seconds > 0 ? `${seconds}s` : ''}`

            const data = {
              sender_id: parseInt(callerId.replace('PR', '')),
              sender_type: 'doctor',
              receiver_id: parseInt(calleeId.userId.replace('PA', '')),
              receiver_type: 'patient',
              caller_id: callEndResult.callId,
              start_time: new Date(callEndResult._startedAt),
              end_time: new Date(endTime),
              call_status: `${callEndResult.endResult}`,
              duration,
              channel_id: channel.id,
            }
            dispatch(saveCallDuration({ data, success: () => ({}) }))
            sendUserMessage(channel, {
              message: JSON.stringify(obj),
              customType: 'VIDEO_CALL',
            }).then(() => {
              toastError('Call Ended')
            })
          }

          call.onReconnecting = (call) => {}
          call.onRemoteAudioSettingsChanged = (call) => {
            //...
          }
          call.onRemoteVideoSettingsChanged = (call) => {
            //...
          }

          // const directCall = await SendBirdCall.getDirectCall(callProps.callId);
          // navigate(isVideoCall ? 'chat-call' : 'chat-call', {state:{ callId: callProps.callId, directCall, nickname: channel.members.find((item)=>item.userId.startsWith("PR")).nickname }});
        }
      }
    }).catch((error) => {
      toastError('')
    })
  }
  const endCall = () => {
    if (callInstance) {
      callInstance.end()
      setShowCall(false)
    }
  }
  const openCamera = () => {
    if (callInstance) {
      callInstance.startVideo()
    }
  }
  const closeCamera = () => {
    if (callInstance) {
      callInstance.stopVideo()
    }
  }
  const muteMicrophone = () => {
    if (callInstance) {
      setMute(!mute)
      if (mute) {
        return callInstance.unmuteMicrophone()
      }
      return callInstance.muteMicrophone()
    }
  }

  const submitMessage = () => {
    let message = {
      file: selectedShortCutFiles,
      url: selectedShortCutLinks,
      message: text,
    }

    const sendUserMessage = sendBirdSelectors.getSendUserMessage(store)
    sendUserMessage(channel, {
      data: JSON.stringify(message),
      message: text,
    })
      // sendUserMessage(channel, {
      //   message: text,
      // })
      // .onSucceeded(() => {
      //   setText('')
      // })
      .onFailed(() => {
        setText('')
        toastError('Send message failed, try again')
      })
      .onSucceeded((message) => {
        setAllMessages((prev) => [...prev, message])
        setText('')
        setSelectedShortCut(null)
        setSelectedShortCutFiles([])
        setSelectedShortCutLinks([])
        scrollToBottom()
        if (selectedChat) {
          var receiverType, receiverUserID
          inputRef.current.value = ''
          if (channel.name == 'Teams') {
            receiverType = 'internal_doctor'
          } else if (channel.name == 'Admin') {
            receiverType = 'admin'
          } else if (channel.name == 'DPCDoctor') {
            receiverType = 'patient'
          }
          receiverUserID = selectedChat.id
        } else if (!selectedChat && channel.name == 'Admin') {
          receiverType = 'admin'
          receiverUserID = 1
        }
        if (message) {
          updateLatestMessage(message)
        }
        if (message && receiverType && receiverUserID) {
          saveChannelDetails(message, receiverType, receiverUserID)
        }
      })
  }
  const updateLatestMessage = (message) => {
    if (message) {
      let data = {
        sendbird_channel_url: message.channelUrl,
      }
      dispatch(UpdateChannelLatestMessage({ data: data }))
    }
  }

  const saveChannelDetails = (message, receiverType, receiverUserID) => {
    if (message) {
      let data = {
        sendbird_user_id: message.sender.userId,
        sendbird_channel_url: message.channelUrl,
        user_type:
          userInfo &&
          userInfo.doctor_login &&
          userInfo.doctor_login.user_type &&
          userInfo.doctor_login.user_type.length > 0
            ? userInfo.doctor_login.user_type
            : 'doctor',
        message_id: message.messageId,
        user_id: userInfo?.id,
        receiver_type: receiverType,
        receiver_user_id: receiverUserID,
      }
      dispatch(SaveChannelMessage({ data: data }))
    }
  }

  const addToMyChat = async (item) => {
    if (
      item.message &&
      item.channelUrl 
      //&&item.message.channelUrl.length > 0
    ) {
      await channel.unhide()
      var hideList = channel.members.filter(
        (x) => x.userId != token && !x.userId.startsWith('PA')
      )
      var dataParams = { data: token }
      fetch(
        `https://api-${SENDBIRD_INFO.appId}.sendbird.com/v3/group_channels/${item.channelUrl}`,
        {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            'Api-Token': SENDBIRD_INFO.sendBirdToken,
          },
          body: JSON.stringify(dataParams),
        }
      )
        .then((updatedChannel) => {
          if (updatedChannel != '') {
            Promise.all([
              hideList.map(async (x) => {
                return fetch(
                  `https://api-${SENDBIRD_INFO.appId}.sendbird.com/v3/group_channels/${item.channelUrl}/hide`,
                  {
                    method: 'PUT',
                    headers: {
                      'Content-Type': 'application/json',
                      'Api-Token': SENDBIRD_INFO.sendBirdToken,
                    },
                    body: JSON.stringify({
                      user_id: x.userId,
                      allow_auto_unhide: false,
                    }),
                  }
                )
              }),
            ]).then((res) => {
              let reqdata = {
                sendbird_channel_url: item.channelUrl,
                assign_user_id: userInfo?.id,
                assign_user_type:
                  userInfo.doctor_login.user_type === null
                    ? 'provider'
                    : 'nonprovider',
                site_type: 'careappweb',
              }
              dispatch(saveChatAssign({ data: reqdata }))

              close()
            })
          } else toastError("Couldn't Move to Assign channel")
        })
        .catch((e) => {
          toastError('Something went wrong')
        })
    }
  }

  const checkForShortcut = (e) => {
    const text = openModal ? shechRef.current.value : inputRef.current.value
    const reg = /#/
    const check = reg.test(text)
    if (check) {
      const textMessage = text.split('#')[1]
      const data = doctorDetails?.data?.text_shortcuts.filter((word) =>
        word.key_word.toLowerCase().includes(textMessage.toLowerCase())
      )
      if (openModal) {
        setschText(text)
        setshowScheShortCuts(true)
        setSchShortcuts(data)
      } else {
        setShortcuts(data)
        setshowShortCuts(true)
        setText(text)
      }
    } else {
      if (openModal) {
        setschText(text)
        setshowScheShortCuts(false)
      } else {
        setText(text)
        setshowShortCuts(false)
      }
    }
  }
  const handleBack = () => {
    close && close()
  }

  const handleReplace = (shortcut) => {
    setSelectedShortCut(shortcut)
    setSelectedShortCutFiles((prevFiles) => {
      // Filter new items to only include those not already in prevFiles
      const uniqueNewFiles = shortcut?.file_name.filter(
        (file) => !prevFiles.includes(file)
      )
      return [...prevFiles, ...uniqueNewFiles]
    })
    setSelectedShortCutLinks((prevLink) => {
      // Filter new items to only include those not already in prevFiles
      const unique = shortcut?.replacement_link.filter(
        (link) => !prevLink.includes(link)
      )
      return [...prevLink, ...unique]
    })

    // let message = {
    //   file: shortcut.file_name,
    //   //fileType:shortcut.file_type,
    //   url: shortcut.replacement_link,
    //   message: shortcut.message,
    // }
    // const sendUserMessage = sendBirdSelectors.getSendUserMessage(store)
    // sendUserMessage(channel, {
    //   data: JSON.stringify(message),
    //   message: shortcut.key_word,
    // })
    //   .onSucceeded(() => {
    //     setText('')
    //   })
    //   .onFailed(() => {
    //     setText('')
    //     toastError('Send message failed, try again')
    //   })
    //   .onSucceeded((message) => {
    //     setText('')
    //     if (selectedChat) {
    //       var receiverType, receiverUserID
    //       inputRef.current.value = ''
    //       if (channel.name == 'Teams') {
    //         receiverType = 'internal_doctor'
    //       } else if (channel.name == 'Admin') {
    //         receiverType = 'admin'
    //       } else if (channel.name == 'DPCDoctor') {
    //         receiverType = 'patient'
    //       }
    //       receiverUserID = selectedChat.id
    //     } else if (!selectedChat && channel.name == 'Admin') {
    //       receiverType = 'admin'
    //       receiverUserID = 1
    //     }
    //     saveChannelDetails(message, receiverType, receiverUserID)
    //   })
    let replacementText = `${shortcut.message}` // Start with the message
    console.log('test-------------', shortcut)
    // if (shortcut.replacement_link && shortcut.replacement_link.length > 0 && shortcut.replacement_link!=null) {
    //     shortcut.replacement_link.forEach((link) => {
    //         replacementText += `\n${link}`;
    //     });
    //     console.log('test-------------',replacementText);

    // }
    // if (shortcut.file_name.length > 0) {
    //      // replacementText += `\n ${doctorDetails?.data?.replacement_url+'/'+shortcut.file_name}`;
    //       shortcut.file_name.forEach((link) => {
    //         replacementText += `\n ${doctorDetails?.data?.replacement_url+'/'+link.filename}`;
    //         //replacementText += `\n${link}`;
    //     });
    //     }
    const newText = text.replace(/#/, replacementText)
    setText(newText)
    inputRef.current.value = newText
    setshowShortCuts(false)
  }

  const handleSchReplace = (shortcut) => {
    setSChSelectedShortCutFiles((prevFiles) => {
      const uniqueNewFiles = shortcut?.file_name.filter(
        (file) => !prevFiles.includes(file)
      )
      return [...prevFiles, ...uniqueNewFiles]
    })
    setSchSelectedShortCutLinks((prevLink) => {
      const unique = shortcut?.replacement_link.filter(
        (link) => !prevLink.includes(link)
      )
      return [...prevLink, ...unique]
    })

    let replacementText = `${shortcut.message}`
    const newText = text.replace(/#/, replacementText)
    setschText(newText || replacementText)
    shechRef.current.value = newText || replacementText
    setshowScheShortCuts(false)
  }
  const removeShortcutFile = (removeData) => {
    console.log(removeData)
    const filteredData = selectedShortCutFiles.filter(
      (shortCutData) => shortCutData.filename != removeData
    )
    setSelectedShortCutFiles(filteredData)
  }
  const removeShortcutLink = (removeData) => {
    console.log(removeData)
    const filteredData = selectedShortCutLinks.filter(
      (shortCutData) => shortCutData != removeData
    )
    setSelectedShortCutLinks(filteredData)
  }

  const removeSchShortcutFile = (removeData) => {
    const filteredData = schSelectedShortCutFiles.filter(
      (shortCutData) => shortCutData.filename != removeData
    )
    setSChSelectedShortCutFiles(filteredData)
  }
  const removeSchShortcutLink = (removeData) => {
    const filteredData = schSelectedShortCutLinks.filter(
      (shortCutData) => shortCutData != removeData
    )
    setSchSelectedShortCutLinks(filteredData)
  }

  const getName = () => {
    if (channel.name === 'Admin') {
      return 'ConnectCare Team'
    } else if (channel.name === 'DPCDoctor') {
      const filtered = channel.members.filter(
        (x) => x.userId.slice(0, 2) === 'PA'
      )
      return filtered?.map((item, index) =>
        filtered.length - 1 === index ? item.nickname : item.nickname + ','
      )
    } else if (channel.name === 'Teams') {
      const filtered = channel.members.filter(
        (x) =>
          x.userId !== currentUser?.userInfo?.doctor_login?.sendbird_user_id
      )
      return filtered?.map((item, index) =>
        filtered.length - 1 === index ? item.nickname : item.nickname + ','
      )
    }
  }

  const handleGetForwarderId = async () => {
    if (channel.name == 'Admin') {
      var forwarder = 'Admin'
      return forwarder
    } else if (channel.name == 'Teams') {
      const forwarderId = channel.members.find(
        (item) => item.userId !== currentUser
      )
      return forwarderId.userId
    } else if (channel.name == 'DPCDoctor') {
      const forwarderId = channel.members.find(
        (item) => item.userId !== currentUser && item.userId.startsWith('PA')
      )
      return forwarderId.userId
    }
  }

  useEffect(() => {
    ;(async () => {
      let id = await handleGetForwarderId()
      setForwardId(id)
    })()
  }, [])

  useEffect(() => {
    if (channel.name == 'Teams' || channel.name == 'Admin') {
      setIsTeam(true)
    }
  }, [channel])

  const formatMessageTime = (timestamp, scheduled) => {
    const date = new Date(timestamp)
    const hours = date.getHours()
    const minutes = date.getMinutes().toString().padStart(2, '0')
    const ampm = hours >= 12 ? 'PM' : 'AM'
    const formattedHours = hours % 12 || 12

    if (scheduled) {
      const day = date.getDate().toString().padStart(2, '0')
      const month = (date.getMonth() + 1).toString().padStart(2, '0') // Months are 0-based
      const year = date.getFullYear()

      return `${month}/${day}/${year} ${formattedHours}:${minutes} ${ampm}`
    }

    return `${formattedHours}:${minutes} ${ampm}`
  }

  function getUpdateInfo(id, message) {
    setMessageId(id)
    setMessage(message)
  }

  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      submitMessage()
    }
  }

  function uploadFile(e) {
    e.preventDefault()
    dispatch(toogleUplaod())
  }

  const initialValues = {
    message: '',
  }

  const handleSubmit = (values) => {
    const [inputHour, inputMinute] = time.split(':').map(Number)

    if (
      isNaN(inputHour) ||
      isNaN(inputMinute) ||
      inputHour < 0 ||
      inputHour > 23 ||
      inputMinute < 0 ||
      inputMinute > 59
    ) {
      console.error(
        'Invalid time format. Please enter a valid time in HH:mm format.'
      )
      return
    }

    const now = new Date()
    const scheduledDate = new Date(startDate ? startDate : now)
    scheduledDate.setHours(inputHour, inputMinute, 0, 0)

    if (scheduledDate <= now) {
      scheduledDate.setDate(scheduledDate.getDate() + 1)
    }

    const scheduledAtInMilliseconds = scheduledDate.getTime()
    const scheduledAtInSeconds = Math.floor(scheduledAtInMilliseconds / 1000)

    const currentTimeInSeconds = Math.floor(now.getTime() / 1000)
    const fiveMinutesFromNow = currentTimeInSeconds + 5 * 60
    const thirtyDaysFromNow = currentTimeInSeconds + 30 * 24 * 60 * 60

    if (
      scheduledAtInSeconds < fiveMinutesFromNow ||
      scheduledAtInSeconds > thirtyDaysFromNow
    ) {
      console.error(
        'Invalid scheduled time. It should be between 5 minutes and 30 days from now.'
      )
      return
    }

    let message = {
      file: schSelectedShortCutFiles,
      url: schSelectedShortCutLinks,
      message: schText || values.message,
    }

    const data = {
      data: JSON.stringify(message),
      scheduled_at: scheduledAtInMilliseconds,
      user_id: token,
      message_type: 'MESG',
      message: schText || values.message,
    }

    fetch(
      `https://api-${SENDBIRD_INFO.appId}.sendbird.com/v3/group_channels/${channel?.url}/scheduled_messages`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Api-Token': SENDBIRD_INFO.sendBirdToken,
        },
        body: JSON.stringify(data),
      }
    )
      .then((res) => {
        if (filterScheduled) {
          retreiveScheduleMessage()
          setOpenModal(false)
        }
        if (!filterScheduled) {
          retreiveScheduleMessage(true)
        }
        toastModal('Schedule message sent  successfully !!')
        setTime('')
        setStartDate(null)
        setSChSelectedShortCutFiles([])
        setSchSelectedShortCutLinks([])
        setschText('')
        setSchShortcuts([])
        scrollToBottom()
        return res.json()
      })
      .then((response) => {
        if (response.error) {
          console.error('Error Response from API:', response)
        } else {
          setOpenModal(false)
        }
      })
      .catch((err) => {
        console.error('Fetch Error:', err)
      })
  }

  const handleTimeChange = (newTime) => {
    setTime(newTime)
  }

  const filteredChannels =
    channel?.name !== 'DPCDoctor' &&
    channel?.members
      ?.filter(
        (item) =>
          item.userId !== currentUser?.userInfo?.doctor_login?.sendbird_user_id
      )
      ?.map((item) => item)



  return (
    <>
      {showCall && (
        <div className='flex justify-center items-center'>
          <ChatCall
            onCallEnd={endCall}
            onStartVideo={openCamera}
            onEndVideo={closeCamera}
            connected={connected}
            mute={mute}
            onMute={muteMicrophone}
          />
        </div>
      )}
      {openModal && (
        <Modal
          title={'Schedule message'}
          className='w-full lg:w-1/2 shadow-2xl'
          close={() => {
            setOpenModal(false)
            setTime('')
            setStartDate(null)
            setSChSelectedShortCutFiles([])
            setSchSelectedShortCutLinks([])
            setschText('')
            setSchShortcuts([])
          }}
        >
          <section className='mt-4 px-4 mb-8'>
            <Formik
              onSubmit={handleSubmit}
              enableReinitialize={true}
              initialValues={initialValues}
            >
              <Form>
                <section className='w-full'>
                  <div className='flex justify-between items-center w-full'>
                    <div className='w-full'>
                      <p>Choose time </p>
                      <CustomTimePicker
                        time={time}
                        onTimeChange={handleTimeChange}
                        date={startDate || new Date()}
                      />
                    </div>
                    <div className='w-full'>
                      <p>Choose any date </p>

                      <DatePicker
                        selected={startDate || new Date()}
                        onChange={(date) => setStartDate(date)}
                        minDate={new Date()}
                        maxDate={
                          new Date(
                            new Date().setDate(new Date().getDate() + 30)
                          )
                        }
                      />
                    </div>
                  </div>
                  <section className='w-full flex-col overflow-y-auto px-2 h-full'>
                    {showScheShortcuts && schShortCuts.length > 0 && (
                      <div className='w-full h-min py-4 border '>
                        {schShortCuts.map((item, index) => (
                          <div
                            className='flex flex-row hover:bg-everglade-400 hover:text-white'
                            onClick={() => handleSchReplace(item)}
                          >
                            {item?.file_name?.length > 0 &&
                              item?.file_name?.map((e) => {
                                return (
                                  <section
                                    className='w-10 m-5 '
                                    key={index + 'img'}
                                  >
                                    {getFileIcon(
                                      e,
                                      doctorDetails?.data?.replacement_url
                                    )}
                                  </section>
                                )
                              })}
                            <section
                              key={index}
                              className='flex flex-col p-2 cursor-pointer mt-2'
                            >
                              <span className='font-semibold text-xl text-everglade-800'>
                                #{item?.key_word}
                              </span>
                              <span className='text-xs pl-2'>
                                {item?.message}
                              </span>
                              {item?.replacement_link?.length > 0 &&
                                item?.replacement_link?.map((link, index) => (
                                  <span className='text-xs pl-2 font-semibold'>
                                    <span class='text-everglade-900'>
                                      {link}
                                    </span>{' '}
                                  </span>
                                ))}
                            </section>
                          </div>
                        ))}
                      </div>
                    )}

                    <div className='relative flex flex-row items-center flex-wrap px-2 pt-2'>
                      {schSelectedShortCutFiles?.length > 0 && (
                        <div className='flex flex-row'>
                          {schSelectedShortCutFiles.map((e) => (
                            <div className='relative remove-block pb-2'>
                              {getFileIcon(
                                e,
                                doctorDetails?.data?.replacement_url
                              )}
                              <IoMdCloseCircle
                                className='remove-icon'
                                onClick={() =>
                                  removeSchShortcutFile(e.filename)
                                }
                              />
                            </div>
                          ))}
                        </div>
                      )}

                      {schSelectedShortCutLinks.map((e) => (
                        <div className='relative remove-block pb-2'>
                          <Badge icon={MdLink}>{e}</Badge>
                          <IoMdCloseCircle
                            className='remove-icon'
                            onClick={() => removeSchShortcutLink(e)}
                          />
                        </div>
                      ))}
                    </div>

                    <div className='flex flex-row w-full gap-5'>
                      <InputTextArea
                        name='message'
                        placeholder='message'
                        cols={80}
                        rows={10}
                        inputRef={shechRef}
                        onChange={(e) => {
                          checkForShortcut(e)
                        }}
                        value={schText}
                        height={70}
                        type='text'
                        color={true}
                        onKeyDown={handleKeyPress}
                        label={'Message'}
                      />
                    </div>
                  </section>
                </section>
                <section className='flex justify-end items-center'>
                  <button
                    type='submit'
                    className='lg:flex border-2   mt-3.5 w-[153PX] h-14 lg:mt-12 rounded border-care-green bg-everglade-700 hover:bg-everglade-400  hover:border-everglade-400 uppercase flex items-center justify-center text-sm poppins font-medium text-white'
                    disabled={schText.trim().length === 0 || time.length === 0}
                  >
                    Send
                  </button>
                </section>
              </Form>
            </Formik>
          </section>
        </Modal>
      )}
      {!showCall && (
        <>
          <div className='sendbird-app__channellist-wrap'>
            {/* <ChannelList /> */}
          </div>
          <div
            className={` ${
              open ? 'w-[100%] ' : 'w-[120%] '
            } flex flex-col  h-[90vh] relative sendbird-app__conversation-wrap`}
          >
            <div>
              <section className='flex items-center justify-between px-10'>
                <section className='flex items-center'>
                  <MdOutlineArrowCircleLeft
                    onClick={handleBack}
                    className='text-everglade-600 cursor-pointer'
                    size={30}
                  />

                  <p className='p-4 pl-8 font-semibold'>{getName()}</p>
                  <div className='flex flex-row items-center justify-between'>
                    {patientStatusInfo?.patients?.is_vip == 1 && (
                      <Icon
                        icon='quill:vip'
                        fontSize={40}
                        color='#ffbf00'
                      ></Icon>
                    )}
                    {locationUIInfo(patientStatusInfo)}
                    {/* {patientStatusInfo?.patients?.is_vip==1 &&  <Badge className="ml-1" color="warning">VIP</Badge>} */}
                    {patientStatusInfo?.patients && (
                      <Badge
                        className='ml-1'
                        size={40}
                        color={
                          patientStatusInfo?.patients.deleted_at == null ||
                          patientStatusInfo?.patients.deleted_at == ''
                            ? 'success'
                            : 'failure'
                        }
                      >
                        {patientStatusInfo?.patients.deleted_at == null ||
                        patientStatusInfo?.patients.deleted_at == ''
                          ? 'Active'
                          : 'InActive'}
                      </Badge>
                    )}
                    {patientStatusInfo?.location_group?.group_name && (
                      <Badge className='ml-1' color='blue' size={40}>
                        {patientStatusInfo?.location_group?.group_name}
                      </Badge>
                    )}
                  </div>
                </section>
                {filterScheduled && (
                  <div>
                    <h2 className='font-semibold'>Scheduled Messages</h2>
                  </div>
                )}
                <section className='flex flex-row items-center gap-4'>
                  <MdArchive
                    onClick={archiveChat}
                    className={`text-everglade-600 cursor-pointer ${
                      !showArchive && 'hidden'
                    }`}
                    size={25}
                  />
                  <MdUnarchive
                    onClick={unArchiveChat}
                    className={`text-everglade-600 cursor-pointer ${
                      !showUnArchive && 'hidden'
                    }`}
                    size={25}
                  />
                  <MdOutlineAddIcCall
                    onClick={initializeCall}
                    className={`text-everglade-600 cursor-pointer ${
                      !showCallIcon && 'hidden'
                    }`}
                    size={25}
                  />
                  <MdScheduleSend
                    size={25}
                    className='text-everglade-600 cursor-pointer'
                    onClick={() => setOpenModal((value) => !value)}
                  />
                </section>
              </section>
            </div>
            <section
              className=' h-[90vh]  pb-4 overflow-y-auto  '
              ref={messagesContainerRef}
            >
              <div className=' p-5 '>
                {filteredMessages.map((message) => {
                  return (
                    <CustomChat
                      addToMyChat={addToMyChat}
                      setMessage={setMessage}
                      setMessageForwardedFrom={setMessageForwardedFrom}
                      channel={channel}
                      formatMessageTime={formatMessageTime}
                      user={user}
                      message={message}
                      allMessages={allMessages}
                      retreiveScheduleMessage={retreiveScheduleMessage}
                      setAllMessages={setAllMessages}
                      getAllMessages={getAllMessages}
                    />
                  )
                })}
              </div>
              <div ref={messagesEndRef} />
            </section>
            <div>
              <section className='w-full flex-col overflow-y-auto px-2 h-full'>
                {showShortcuts && shortCuts.length > 0 && (
                  <div className='w-full h-min py-4 border '>
                    {shortCuts.map((item, index) => (
                      <div
                        className='flex flex-row hover:bg-everglade-400 hover:text-white'
                        onClick={() => handleReplace(item)}
                      >
                        {/* { item?.file_name?.map((e)=>{
                          return e.filename
                        })} */}
                        {item?.file_name?.length > 0 &&
                          item?.file_name?.map((e) => {
                            ;<section className='w-10 m-5 ' key={index + 'img'}>
                              {getFileIcon(
                                e,
                                doctorDetails?.data?.replacement_url
                              )}
                            </section>
                          })}
                        <section
                          key={index}
                          className='flex flex-col p-2 cursor-pointer mt-2'
                        >
                          <span className='font-semibold text-xl text-everglade-800'>
                            #{item?.key_word}
                          </span>
                          <span className='text-xs pl-2'>{item?.message}</span>
                          {item?.replacement_link?.length > 0 &&
                            item?.replacement_link?.map((link, index) => (
                              <span className='text-xs pl-2 font-semibold'>
                                <span class='text-everglade-900'>{link}</span>{' '}
                              </span>
                            ))}
                        </section>
                      </div>
                    ))}
                  </div>
                )}

                <div className='relative flex flex-row items-center flex-wrap px-2 pt-2'>
                  {selectedShortCutFiles?.length > 0 && (
                    <div className='flex flex-row'>
                      {selectedShortCutFiles.map((e) => (
                        <div className='relative remove-block pb-2'>
                          {getFileIcon(e, doctorDetails?.data?.replacement_url)}
                          {/* <Avatar img={doctorDetails?.data?.replacement_url+'/'+e.filename} className='relative img-block'/> */}
                          <IoMdCloseCircle
                            className='remove-icon'
                            onClick={() => removeShortcutFile(e.filename)}
                          />
                        </div>
                      ))}
                    </div>
                  )}

                  {selectedShortCutLinks.map((e) => (
                    <div className='relative remove-block pb-2'>
                      <Badge icon={MdLink}>{e}</Badge>
                      <IoMdCloseCircle
                        className='remove-icon'
                        onClick={() => removeShortcutLink(e)}
                      />
                    </div>
                  ))}
                </div>

                {filterScheduled ? (
                  <></>
                ) : patientStatusInfo?.patients.deleted_at == null ||
                  patientStatusInfo?.patients.deleted_at == '' ? (
                  <div className='border-2 w-full flex flex-row items-center px-2'>
                    <IoMdAttach
                      className=' text-everglade-500 hover:cursor-pointer gap-5 my-2'
                      size={30}
                      onClick={uploadFile}
                    />
                    <textarea
                      ref={inputRef}
                      onChange={checkForShortcut}
                      value={text}
                      placeholder='Enter Message'
                      onKeyDown={handleKeyPress}
                      className=' border-0 outline-0  focus:border-none  border-none flex w-[100%] mx-2'
                    />
                    {text !== '' && (
                      <button
                        className='text-everglade-600 mx-2'
                        onClick={submitMessage}
                      >
                        <AiOutlineSend size={30} />
                      </button>
                    )}
                  </div>
                ) : (
                  <div className='border-2 w-full text-center  p-2 bg-red-400'>
                    <h1 className='font-extrabold'>
                      Your Coverage is EXPIRED, You cannot chat with a doctor.
                      Please contact the ConnectCare Team.
                    </h1>
                  </div>
                )}
              </section>
            </div>
            {!filterScheduled && (
              <div className='border-2 w-full flex flex-row items-center px-2 absolute bottom-0 left-0 right-0 bg-white'>
                <IoMdAttach
                  className=' text-everglade-500 hover:cursor-pointer gap-5 my-2'
                  size={30}
                  onClick={uploadFile}
                />
                <textarea
                  ref={inputRef}
                  onChange={checkForShortcut}
                  value={text}
                  placeholder='Enter Message'
                  onKeyDown={handleKeyPress}
                  className=' border-0 outline-0  focus:border-none  border-none flex w-[100%] mx-2'
                />
                {text !== '' && (
                  <button
                    className='text-everglade-600 mx-2'
                    onClick={submitMessage}
                  >
                    <AiOutlineSend size={30} />
                  </button>
                )}
              </div>
            )}
          </div>
        </>
      )}
      {message && !messageId && (
        <Modal
          title={'Forward Message'}
          close={() => {
            setMessage('')
            setIsTeam(false)
          }}
        >
          <ForwardMessageModal
            channel={channel}
            forwardedFrom={messageForwardedFrom}
            message={message}
            isTeam={isTeam}
            setIsTeam={setIsTeam}
            close={() => {
              setMessage('')
              setMessageForwardedFrom('')
              setIsTeam(false)
            }}
            unClose={close}
            data={PatientListinfo}
            id={forwardId}
            store={store}
            patientList={patientList}
            filteredChannels={filteredChannels}
          />
        </Modal>
      )}
      {/* {messageId && message && <Modal close={()=> { setMessageId('');setMessage('') }} >
        <UpdateMessage channel={channel} message={message} messageId={messageId} close={()=>{ setMessageId('');setMessage('') }}  sendBirdSelectors={sendBirdSelectors} store={store}/>
         </Modal> } */}
    </>
  )
}

export default ChatUI
