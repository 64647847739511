import { Badge } from 'flowbite-react'
import { Form, Formik } from 'formik'
import { useEffect, useState } from 'react'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { IoMdCloseCircle } from 'react-icons/io'
import { MdLink } from 'react-icons/md'
import { SENDBIRD_INFO } from '../../../utils/helpers/constant'
import { InputTextArea } from '../input'
import { getFileIcon } from '../input/fileUtils'
import CustomTimePicker from './customTimePicker'

export default function EditMessage({
  message,
  channel,
  onClose,
  wholeMessage,
  retreiveScheduleMessage,
  setAllMessages,
  messageStatus,
  getAllMessages,
  doctorDetails,
}) {
  const initialValues = { message }

  const [selectedShortCutFiles, setSelectedShortCutFiles] = useState([])
  const [messageUrl, setMessageUrl] = useState([])

  const [startDate, setStartDate] = useState(
    wholeMessage?.scheduledInfo?.scheduledAt || new Date()
  )

  const [time, setTime] = useState('')

  const handleTimeChange = (newTime) => {
    setTime(newTime)
  }

  useEffect(() => {
    if (wholeMessage?.data) {
      const data = JSON.parse(wholeMessage.data)
      setSelectedShortCutFiles(data.file)
      setMessageUrl(data.url)
    }
  }, [wholeMessage])

  useEffect(() => {
    const scheduledAt = wholeMessage?.scheduledInfo?.scheduledAt
    const date = new Date(scheduledAt)

    const hours = date.getHours().toString().padStart(2, '0')
    const minutes = date.getMinutes().toString().padStart(2, '0')

    const formattedTime = `${hours}:${minutes}`
    setTime(formattedTime)
  }, [wholeMessage?.scheduledInfo?.scheduledAt])

  const url = `https://api-${
    SENDBIRD_INFO.appId
  }.sendbird.com/v3/group_channels/${channel?.url}/${
    messageStatus
      ? `scheduled_messages/${wholeMessage.scheduledInfo.scheduledMessageId}`
      : `messages/${wholeMessage.messageId}`
  }`

  const handleSubmit = async (values) => {
    let scheduled_at = null
    let data = ''
    if (wholeMessage?.data) {
      let message = {
        file: selectedShortCutFiles,
        url: messageUrl,
        message: values.message,
      }
      data = message
    }

    if (messageStatus) {
      const [inputHour, inputMinute] = time.split(':').map(Number)

      if (
        isNaN(inputHour) ||
        isNaN(inputMinute) ||
        inputHour < 0 ||
        inputHour > 23 ||
        inputMinute < 0 ||
        inputMinute > 59
      ) {
        console.error(
          'Invalid time format. Please enter a valid time in HH:mm format.'
        )
        return
      }

      const now = new Date()
      const scheduledDate = new Date(startDate ? startDate : now)
      scheduledDate.setHours(inputHour, inputMinute, 0, 0)

      if (scheduledDate <= now) {
        scheduledDate.setDate(scheduledDate.getDate() + 1)
      }

      const scheduledAtInMilliseconds = scheduledDate.getTime()
      const scheduledAtInSeconds = Math.floor(scheduledAtInMilliseconds / 1000)

      const currentTimeInSeconds = Math.floor(now.getTime() / 1000)
      const fiveMinutesFromNow = currentTimeInSeconds + 5 * 60
      const thirtyDaysFromNow = currentTimeInSeconds + 30 * 24 * 60 * 60

      if (
        scheduledAtInSeconds < fiveMinutesFromNow ||
        scheduledAtInSeconds > thirtyDaysFromNow
      ) {
        console.error(
          'Invalid scheduled time. It should be between 5 minutes and 30 days from now.'
        )
        return
      }
      scheduled_at = scheduledAtInMilliseconds
    }

    const params = {
      data: JSON.stringify(data),
      message: values.message,
      ...(messageStatus
        ? {
            scheduled_at,
          }
        : { message_type: 'MESG' }),
    }
    try {
      await fetch(url, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Api-Token': SENDBIRD_INFO.sendBirdToken,
        },
        body: JSON.stringify(params),
      })

      setAllMessages([])
      messageStatus ? retreiveScheduleMessage() : getAllMessages()
    } catch (err) {
      console.error('Failed to update message:', err)
    } finally {
      onClose()
    }
  }

  const removeShortcutFile = (removeData) => {
    const filteredData = selectedShortCutFiles.filter(
      (shortCutData) => shortCutData.filename != removeData
    )
    setSelectedShortCutFiles(filteredData)
  }

  const removeShortcutLink = (removeData) => {
    const filteredData = messageUrl.filter(
      (shortCutData) => shortCutData != removeData
    )
    setMessageUrl(filteredData)
  }

  return (
    <div className='mt-4 px-4 mb-8'>
      <Formik
        onSubmit={handleSubmit}
        enableReinitialize={true}
        initialValues={initialValues}
      >
        <Form>
          <section className='w-full'>
            {messageStatus && (
              <div className='flex justify-between items-center w-full'>
                <div className='w-full'>
                  <p>Choose time </p>
                  <CustomTimePicker
                    time={time}
                    onTimeChange={handleTimeChange}
                    date={startDate}
                  />
                </div>
                <div className='w-full'>
                  <p>Choose any date </p>

                  <DatePicker
                    selected={startDate}
                    onChange={(date) => setStartDate(date)}
                    minDate={new Date()}
                    maxDate={
                      new Date(new Date().setDate(new Date().getDate() + 30))
                    }
                  />
                </div>
              </div>
            )}
            <div className='relative flex flex-row items-center flex-wrap px-2 pt-2'>
              {selectedShortCutFiles?.length > 0 && (
                <div className='flex flex-row'>
                  {selectedShortCutFiles.map((e) => (
                    <div className='relative remove-block pb-2'>
                      {getFileIcon(e, doctorDetails?.data?.replacement_url)}
                      <IoMdCloseCircle
                        className='remove-icon'
                        onClick={() => removeShortcutFile(e.filename)}
                      />
                    </div>
                  ))}
                </div>
              )}
              {messageUrl?.map((e) => (
                <div className='relative remove-block pb-2'>
                  <Badge icon={MdLink}>{e}</Badge>
                  <IoMdCloseCircle
                    className='remove-icon'
                    onClick={() => removeShortcutLink(e)}
                  />
                </div>
              ))}
            </div>
          </section>
          <InputTextArea
            name='message'
            placeholder='message'
            cols={80}
            rows={10}
            height={70}
            type='text'
            color={true}
            label={'Message'}
          />
          <section className='flex justify-end items-center'>
            <button
              type='submit'
              className='lg:flex border-2   mt-3.5 w-[153PX] h-14 lg:mt-12 rounded border-care-green bg-everglade-700 hover:bg-everglade-400  hover:border-everglade-400 uppercase flex items-center justify-center text-sm poppins font-medium text-white'
            >
              Send
            </button>
          </section>
        </Form>
      </Formik>
    </div>
  )
}
