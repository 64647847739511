import { useEffect, useState } from "react";
import { MdOutlineArrowCircleLeft } from 'react-icons/md';
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import Chat from "../Components/shared/chat";
import ChatWithDPC from "../Components/shared/chat/chatWithDPC";
import ChatWithPatient from "../Components/shared/chat/chatWithPatient";
import { getPatientList } from "../redux/actions/passActions";
import { createChannel, toastError } from "../utils/helpers/methods";
import { unToogleFilter } from "../redux/slices/chat";

const ChatTeam = ({ type, sdk, user,onClose }) => {
  const { userToken, userInfo } = useSelector((state) => state.auth);
  const [activeTab, setActiveTab] = useState(1);
  const [currentType, setCurrentType] = useState('patients');
  const [channelInstance, setChannelInstance] = useState('');
  const { PatientListinfo, loading } = useSelector(state => state.pass);
  const [selectedChatInstance, setSelectedChatInstance] = useState('');
  const dispatch = useDispatch();



  const handleTabClick = (tabIndex) => {
    setActiveTab(tabIndex);
  }
  useEffect(() => {
    setCurrentType(type);
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    let obj = {
      token: userToken,
      practitioner_id: userInfo.id,
      employer_filter: "",
      patient_tag: "",
      search_key: "",
      limit:'100'
    }
    dispatch(getPatientList(obj));
    //eslint-disable-next-line

  }, [])

  const handleSelected = async (item) => {
    setSelectedChatInstance(item)
    dispatch(unToogleFilter())
    if (type === "teams") {
      const channel = await createChannel(item.doctor_login.sendbird_user_id, 'Teams', sdk, "Teams").then(resp => resp);
      setChannelInstance(channel);
      // navigate(`/chats`, { state: { channel: channel } });
    }
    if (type === "patients") {
      const providerlist = item.practice != null && item.practice.def_provider_id != null && item.practice.providers != null && item.practice.providers.filter((x) => x.doctor_login != null && x.doctor_login.sendbird_user_id != null).map((x) => x.doctor_login.sendbird_user_id)
      const nonProviderlist = item.practice != null && item.practice.def_provider_id != null && item.practice.nonproviders != null && item.practice.nonproviders.filter((x) => x.doctor_login != null && x.doctor_login.sendbird_user_id != null).map((x) => x.doctor_login.sendbird_user_id)
      const combinedList= [...(providerlist || []), ...(nonProviderlist || [])];
      // const doctors = available.map(item => item?.patient_login?.sendbird_user_id);
      if (combinedList.length > 0) {
        const members = combinedList.concat(item.patient_login?.sendbird_user_id);
        const channel=await createChannel(members, 'DPCDoctor', sdk, "DPCDoctor").then(resp =>resp);
        setChannelInstance(channel);
      }
      else {
        toastError("This patient is not assigned yet.");
      }
    }
    // navigate("/chats");
  }
  const handleBack=()=>{
    onClose && onClose();
  }
  const handleSendMessage=(message)=>{
    const params=sdk.UserMessageParams();
    params.message=message;
    // channelInstance.sendUserMessage(params);
   }

  return (
    <>
      {channelInstance ==="" && type!=="" && <div className="w-full mx-auto">
        <div className="">
          <section className='w-full h-14 p-8 flex text-everglade-800 justify-start items-center font-semibold text-xl '><MdOutlineArrowCircleLeft onClick={handleBack} className='cursor-pointer capitalize' size={30} /> <p className="capitalize">{" Chat With "+currentType}</p></section>
          {/* <div className="container md:mx-auto flex items-center justify-between px-4 py-2">
           
          <div className="flex border-b border-gray-300 w-full">
            <div className={`flex-1 text-center px-4 py-2 uppercase cursor-pointer font-medium ${activeTab === 1 ? 'border-b-2 border-everglade-500 text-everglade-500' : 'text-everglade-900'}`} onClick={() => handleTabClick(1)}>
            Available
            </div>
            <div className={`flex-1 text-center px-4 py-2 uppercase cursor-pointer font-medium ${activeTab === 2 ? 'border-b-2 border-everglade-500 text-everglade-500' : 'text-everglade-900'}`} onClick={() => handleTabClick(2)}>
            UnAvailable
            </div>
          </div>
            <div className="w-full flex md:flex-row flex-col shadow-sm">
              <button
                className={`${activeTab === 1
                  ? 'border-everglade-800  text-everglade-800 '
                  : 'border-transparent text-gray-500 hover:text-gray-700'
                  } whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm uppercase flex-1 text-center `}
                onClick={() => handleTabClick(1)}
              >
                
              </button>
              <button
                className={`${activeTab === 2
                  ? 'border-everglade-800  text-everglade-800 '
                  : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
                  } md:ml-8 whitespace-nowrap py-4 px-1 border-b-2 font-medium text-left text-sm uppercase text-center flex-1`}
                onClick={() => handleTabClick(2)}
              >
                 
              </button>
            </div>
          </div> */}
          <div className="w-full ">

            {currentType === "patients" && <ChatWithPatient handleSelected={handleSelected} activeTab={activeTab} data={PatientListinfo} loading={loading} />}

            {/* teams section */}

            {currentType === "teams" && <ChatWithDPC handleSelected={handleSelected} activeTab={activeTab} data={PatientListinfo} loading={loading} />}

          </div>
        </div>
      </div>}
      {channelInstance!=="" &&<Chat onClose={()=>{
        setChannelInstance('');setSelectedChatInstance('')
      }} onSendMessage={handleSendMessage} channel={channelInstance}selectedChat={selectedChatInstance} />}
    </>
  );
};
export default ChatTeam; 