/* eslint-disable jsx-a11y/no-redundant-roles */
import useSendbirdStateContext from "@sendbird/uikit-react/useSendbirdStateContext";
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import Chat from ".";
import ChatTeam from '../../../pages/chatTeam';
import ChatFloatingButton from './chatFloatingButton';
import ChatFloatingStatus from './chatFloatingStatus';
import { createChannel } from '../../../utils/helpers/methods';
import ChanneListPreview from './chatListPreview';
import { SaveAssignChatNormal } from "../../../redux/actions/chat";

const ChatPreview = ({newType}) => {
  const store = useSendbirdStateContext();
  var sdk = store?.stores.sdkStore.sdk;
  const user = store?.stores.userStore.user;
  const [channelInstance, setChannelInstance] = useState('');
  const userId = useSelector(state => state.auth);
  const [activeTab, setActiveTab] = useState('tab0');
  const [type, setType] = useState('');
  const [selectedChat, setSelectedChat] = useState();
  const { assignedMessage,allMessage } = useSelector((state) => state.notification)
  const dispatch = useDispatch();



  const chekChannel = async(item) =>{
    const providerlist = item.patients.practice != null && item.patients.practice.providers != null && item.patients.practice.providers.filter((x) => x.doctor_login != null && x.doctor_login.sendbird_user_id != null).map((x) => x.doctor_login.sendbird_user_id)
    const nonProviderlist = item.patients.practice != null && item.patients.practice.nonproviders != null && item.patients.practice.nonproviders.filter((x) => x.doctor_login != null && x.doctor_login.sendbird_user_id != null).map((x) => x.doctor_login.sendbird_user_id)
    const combinedList= [...(providerlist || []), ...(nonProviderlist || [])]
    const data ={}
    if(item?.patients?.patientlocation.length>0 ){
      data.location = JSON.stringify(item?.patients?.patientlocation[0].id)
    }
    if(item?.patients?.primary_team_id!=null && item?.patients?.primary_team_id!="" && item?.patients?.primary_team_id!=undefined ){
      data.team = JSON.stringify(item?.patients?.primary_team_id)   
    }
    const upsertIfKeyNotExist = true;
    
    if (combinedList.length > 0) {
      const members = combinedList.concat(item?.patient_login?.sendbird_user_id);
      const channel = await createChannel(members, 'DPCDoctor', sdk, "DPCDoctor").then(resp =>resp);
      setChannelInstance(channel);
      await channel.updateMetaData(data,upsertIfKeyNotExist);
    }
  }


  useEffect(()=>{
    if(newType){
      chekChannel(newType);
      if(newType?.employee_details){
      setSelectedChat(newType?.employee_details);
      }
    }
  },[newType])

  useEffect(() => {
    if (sdk.updateCurrentUserInfo) {
      if (user?.nickname === "") {
        const params = {
          nickname: userId?.userInfo.name
        };
        sdk.updateCurrentUserInfo(params).then(resp => {
          window.location.reload();
        });
      }
    }
  //eslint-disable-next-line
  }, [sdk]);

  const handleSelected = (typeSelected) => {
    if (typeSelected !== "connectCare") {
      setType(typeSelected);
    }
  }

  const handleStatusChange = (status) => {
    if (status == "offline") {
     const assignedURL=allMessage.map((data=>data.url))
     console.log("assignedURL",assignedURL);
     const payload={
      sendbird_channel_url:assignedURL
     }
     dispatch(SaveAssignChatNormal({ data: payload }))

    }
  }
  const handleClose = () => {
    setType('');
    setChannelInstance('')
  }

  const handleChatView = (item) => {
    setChannelInstance(item);
  }

  const handleMessage = (message) => {
    // channelInstance.sendUserMessage({message:message});

  }

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  return (
    <>
      {channelInstance === "" && type === "" && <div className="sendbird-app__channellist-wrap w-full flex flex-col" style={{ width: '100%' }}>
        <div>
          <div className="flex border-b border-gray-300">
          <div className={`flex-1 text-center px-4 py-2 uppercase cursor-pointer font-medium ${activeTab === 'tab0' ? 'border-b-2 border-everglade-500 text-everglade-500' : 'text-everglade-900'}`} onClick={() => handleTabClick('tab0')}>
              Practice
            </div>
            <div className={`flex-1 text-center px-4 py-2 uppercase cursor-pointer font-medium ${activeTab === 'tab1' ? 'border-b-2 border-everglade-500 text-everglade-500' : 'text-everglade-900'}`} onClick={() => handleTabClick('tab1')}>
              Assigned
            </div>
            <div className={`flex-1 text-center px-4 py-2 uppercase cursor-pointer font-medium ${activeTab === 'tab2' ? 'border-b-2 border-everglade-500 text-everglade-500' : 'text-everglade-900'}`} onClick={() => handleTabClick('tab2')}>
              Archived
            </div>
            <div className={`flex-1 text-center px-4 py-2 uppercase cursor-pointer font-medium ${activeTab === 'tab3' ? 'border-b-2 border-everglade-500 text-everglade-500' : 'text-everglade-900'}`} onClick={() => handleTabClick('tab3')}>
              Call history
            </div>
          </div>
          <div className="p-4">
            {activeTab === 'tab0' && <ChanneListPreview handleChatView={handleChatView} currentUser={user} activeTab={activeTab} handleClose={handleClose}/>}
            {activeTab === 'tab1' && <ChanneListPreview handleChatView={handleChatView} currentUser={user} activeTab={activeTab} handleClose={handleClose}/>}
            {activeTab === 'tab2' && <ChanneListPreview handleChatView={handleChatView} currentUser={user} activeTab={activeTab} handleClose={handleClose}/>}
            {activeTab === 'tab3' && <ChanneListPreview handleChatView={handleChatView} currentUser={user} activeTab={activeTab} handleClose={handleClose}/>}
          </div>      
        </div>
        {activeTab === 'tab0' &&<ChatFloatingButton handleConnect={(channel) => {
          setChannelInstance(channel);
        }} onSelected={handleSelected} />}
        {activeTab === 'tab1' &&<ChatFloatingStatus onSelected={handleStatusChange} />}
      </div>
      }
      {channelInstance === "" && type !== "" && <section className="w-full h-full"><ChatTeam onClose={handleClose} type={type} sdk={sdk} /></section>}
      {channelInstance !== "" && <Chat activeTab={activeTab} selectedChat={selectedChat} onClose={handleClose} channel={channelInstance} onSendMessage={handleMessage} />}
    </>
  );
}
export default ChatPreview;