import Dropzone from 'react-dropzone'
import { useState } from 'react'

const UplaodProfile = ({ setFile, close, setImageUrl }) => {


  
  const [fileName, setFileName] = useState('')
  const onDrop = (File) => {
    if (File.length > 0) {
      setFileName(File[0].name)
      setFile(File[0])
      setImageUrl(URL.createObjectURL(File[0]))
      close()
    }
  }

  const [isDragging, setIsDragging] = useState(false)



  return (
    <div className='w-[520px] h-min p-2 mt-2 rounded-md shadow-6xl'>
      
      <section className='mt-8 px-4'>
        <section className='space-y-5 lg:space-y-0 '>
          <div>
            <Dropzone
              multiple={false}
              onDrop={onDrop}
              onDragEnter={() => setIsDragging(true)}
              onDragLeave={() => setIsDragging(false)}
            >
              {({ getRootProps, getInputProps }) => (
                <section
                  className={` relative border-dashed border w-full h-[80px]  mt-2 mb-2 ${isDragging ? ' border-everglade-600' : 'border-gray-400'
                    }`}
                >
                  <div
                    {...getRootProps({
                      className:
                        'text-center absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-full',
                    })}
                  >
                    <input {...getInputProps()} />
                    {fileName.length > 0 ? (
                      <div className=' font-bold'>{fileName}</div>
                    ) : (
                      <div  className='font-bold'>
                        <p>
                          Drag and drop file here, or click to select file
                        </p>
                      </div>
                    )}
                  </div>
                </section>
              )}
            </Dropzone>
          </div>
        </section>
      </section>
    </div>
  )
}

export default UplaodProfile
