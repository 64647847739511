function CustomTimePicker({ time, onTimeChange, date }) {
  function isNotToday(date) {
    const today = new Date()
    const inputDate = new Date(date)

    return (
      today.getFullYear() !== inputDate.getFullYear() ||
      today.getMonth() !== inputDate.getMonth() ||
      today.getDate() !== inputDate.getDate()
    )
  }
  const isItToday = !isNotToday(date)
  const now = new Date()
  const currentHours = now.getHours()
  const currentMinutes = now.getMinutes()
  const timeOptions = Array.from({ length: 48 }, (_, i) => {
    const hours = String(Math.floor(i / 2)).padStart(2, '0')
    const minutes = i % 2 === 0 ? '00' : '30'
    return `${hours}:${minutes}`
  })

  function isWithinFiveMinutes(timeString) {
    console.log({ timeString })
    const [hours, minutes] = timeString.split(':').map(Number)

    const timeInMinutes = hours * 60 + minutes
    const currentTimeInMinutes = currentHours * 60 + currentMinutes

    return timeInMinutes <= currentTimeInMinutes + 5
  }

  return (
    <div className='w-[50%]'>
      <select
        value={time}
        onChange={(event) => onTimeChange(event.target.value)}
        className='w-full  border border-everglade-500 appearance-none   rounded   mb-3 leading-tight focus:outline-none focus:shadow-outline focus:border-everglade-500 focus:ring-1 focus:ring-everglade-500  px-2 h-10 '
      >
        <option value='' disabled>
          Select a time
        </option>
        {timeOptions.map((option) => {
          const [hours, minutes] = option.split(':').map(Number)
          const isPast =
            isItToday &&
            (hours < currentHours ||
              (hours === currentHours && minutes < currentMinutes))
          const isWithinBuffer = isItToday && isWithinFiveMinutes(option)
          console.log({ isWithinBuffer })
          return (
            <option
              key={option}
              value={option}
              disabled={isPast || isWithinBuffer}
            >
              {option}
            </option>
          )
        })}
      </select>
    </div>
  )
}

export default CustomTimePicker
