import { useState, useRef, useEffect } from "react";

const MultiSelect = ({component, options, handleSelection ,property}) => {
  const [selectedValues, setSelectedValues] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null); // Ref for tracking clicks outside

  // Close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  const toggleDropdown = () => setIsOpen(!isOpen);

  const handleCheckboxChange = (id) => {
    const updatedSelection = selectedValues.includes(id)
      ? selectedValues.filter((item) => item !== id)
      : [...selectedValues, id];

    setSelectedValues(updatedSelection);
    handleSelection(updatedSelection); // Send selected values to parent
  };

  return (
    <div className="relative w-64" ref={dropdownRef}>
      <button
        onClick={toggleDropdown}
        className="border px-4 py-2 w-full text-left rounded-md shadow-sm text-gray-700 hover:bg-gray-50"
      >
        {selectedValues.length > 0 ? `${selectedValues.length} selected` : "Please Select "+component}
      </button>

      {isOpen && (
        <div className="absolute mt-2 w-full bg-white border border-gray-300 rounded-md shadow-lg max-h-40 overflow-y-auto">
          {options.map((e) => (
            <label key={e.id} className="flex items-center px-4 py-2 hover:bg-gray-100 cursor-pointer">
              <input
                type="checkbox"
                checked={selectedValues.includes(e.id)}
                onChange={() => handleCheckboxChange(e.id)}
                className="mr-2 text-everglade-500"
              />
              {e[property]}
            </label>
          ))}
        </div>
      )}
    </div>
  );
};

export default MultiSelect;
