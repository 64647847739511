import { Icon } from '@iconify/react'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useLocation } from 'react-router-dom'
import { PatientListfilter, getPatientList } from '../redux/actions/passActions'
import Pagination from './pagination'
import Avatar from './shared/chat/avatar'
import Table from './shared/table'
import { getfilterData, togglePatientDetail } from '../redux/slices/filter'
import { Badge, Dropdown, Label, Select, Spinner,Accordion, FloatingLabel } from 'flowbite-react'
import { Menu, Listbox } from '@headlessui/react'
import { backendURL } from '../config/api'
import { AiOutlineEye } from 'react-icons/ai'
import { CityCodes } from './shared/CityCode'
import MultiSelect from './MultiSelect'

const PatientList = () => {
  const { userToken, userInfo } = useSelector((state) => state.auth)
  const { filterData, ispatientDetail } = useSelector((state) => state.filter)
  const { PatientListinfo, PatientList, Patientfilter } = useSelector(
    (state) => state.pass
  )
  const [selectedItemName, setSelectedItemName] = useState('')
  const [selectedGroupName, setSelectedGroupName] = useState('')
  let patientUrl = `${backendURL}/img/patient`
  const dispatch = useDispatch()
  const [PatientFilter, setPatientFilter] = useState([])
  const [PatientFilterval, setPatientFilterval] = useState({
    group: '',
    tag: '',
    search: '',
    patient_login: '',
    dob:'',
    status:'',
    locations:[],
    team:[]
  })
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    setIsLoading(true)
    const timer = setTimeout(() => {
      setIsLoading(false)
    }, 2000)

    return () => clearTimeout(timer)
  }, [PatientList])

  const [currentPage, setCurrentPage] = useState(1)
  const location = useLocation()

  function handlePageChange(pageNumber) {
    setCurrentPage(pageNumber);
    let obj = {
      token: userToken,
      practitioner_id: userInfo.id,
      employer_filter: selectedGroupName,
      patient_tag: PatientFilterval.tag,
      search_key: PatientFilterval.search,
      page: pageNumber,
      patient_login: PatientFilterval.patient_login,
      dob: PatientFilterval.dob,
      status:PatientFilterval.status,
      locations:PatientFilterval.locations,
      team:PatientFilterval.team
    }
    dispatch(getPatientList(obj))
  }

  useEffect(() => {
    //   dispatch(getListPasswords({ token: userToken }));
    const data = location?.state
    if (ispatientDetail) {
      setPatientFilterval({ ...filterData })
    }
    if(data && data.option){
      setPatientFilterval({ ...PatientFilterval, patient_login: data.option })
      let obj = {
        token: userToken,
        practitioner_id: userInfo.id,
        employer_filter: "",
        patient_tag: "",
        limit: 5,
        patient_login: data.option,
        dob: PatientFilterval.dob,
        status:PatientFilterval.status,
        locations:PatientFilterval.locations,
        team:PatientFilterval.team,
      }
      dispatch(getPatientList(obj))
    } else if(data && data.search_key) {
      setPatientFilterval({ ...PatientFilterval, search: data.search_key })
      let obj = {
        token: userToken,
        practitioner_id: userInfo.id,
        employer_filter: "",
        patient_tag: "",
        search_key: data.search_key,
        limit: 5,
        patient_login: PatientFilterval.patient_login,
        dob: PatientFilterval.dob,
        status:PatientFilterval.status      }
      dispatch(getPatientList(obj))
    } else {
      // setPatientFilterval({ ...PatientFilterval, search: data });
      let obj = {
        token: userToken,
        practitioner_id: userInfo.id,
        employer_filter: '',
        patient_tag: '',
        search_key: data ? data : '',
        limit: 5,
        patient_login: '1',
        dob: PatientFilterval.dob,
        status:PatientFilterval.status      }
      dispatch(getPatientList(obj))
    }
    dispatch(
      PatientListfilter({
        practitioner_id: userInfo.id,
        token: userToken,
      })
    )

    setTimeout(() => {
      dispatch(togglePatientDetail(false))
      if (PatientListinfo && PatientListinfo.data) {
        setPatientFilter(Patientfilter)
      }
    }, 500)
    //eslint-disable-next-line
  }, [])
  const handleChangetag = (event) => {
    console.log({event:event.target.value})
    setPatientFilterval({ ...PatientFilterval, tag: event.target.value })

    // make API call with selectedOption
  }
  const handleChangegroup = (event) => {
    setPatientFilterval({ ...PatientFilterval, group: event.target.value })
    // make API call with selectedOption
  }
  const handleChangesearch = (event) => {
    setPatientFilterval({ ...PatientFilterval, search: event.target.value })
    // make API call with selectedOption
  }
  const handleDOBChanges=(event)=>{
    const inputDate = event.target.value; 
    if (inputDate) {
      const [year, month, day] = inputDate.split('-');
      setPatientFilterval({ ...PatientFilterval, dob: `${month}/${day}/${year}` })
    } else {
      setPatientFilterval({ ...PatientFilterval, dob: event.target.value })
    }
  }
  const handleStatusChanges=(event)=>{
     setPatientFilterval({ ...PatientFilterval, status: event.target.value })
  }

  const FilterType = (event) => {
    setPatientFilterval({ ...PatientFilterval, patient_login: event.target.value })
    return
  }

  const handleSelectedLocations = (selectedIds) => {
    console.log("Selected Location IDs:", selectedIds);
    setPatientFilterval({ ...PatientFilterval, locations: selectedIds})
  };

  const handleSelectedTeams = (selectedIds) => {
    console.log("Selected team IDs:", selectedIds);
    setPatientFilterval({ ...PatientFilterval, team: selectedIds })
  };

  const handlefilter = () => {
    let obj = {
      token: userToken,
      practitioner_id: userInfo.id,
      employer_filter: selectedGroupName,
      patient_tag: PatientFilterval.tag,
      search_key: PatientFilterval.search,
      patient_login: PatientFilterval.patient_login,
      dob:PatientFilterval.dob,
      status:PatientFilterval.status,
      team:PatientFilterval.team,
      locations:PatientFilterval.locations
    }
    dispatch(getPatientList(obj))
    dispatch(getfilterData(PatientFilterval))
    // setPatihandleGroupChangeentList([])
    // setTimeout(() => {
    //   (PatientListinfo);
    //   setPatientList(PatientListinfo.data.patients)
    // }, 2000);
  }


  const columns = [
    {
      header: '',
      cell: (row) => (
        <Avatar
          fname={!row.address.profile_image && row.address.first_name}
          lname={!row.address.profile_image && row.address.last_name}
          image={
            row.address.profile_image
              ? `${patientUrl}/${row.address.profile_image}`
              : ''
          }
          className={' hover:cursor-pointer'}
          showStatus={false}
        />
      ),
    },
    {
      header: 'Name',
      cell: (row) => <div className='flex flex-row'>{row?.address?.first_name+ ' '+row?.address?.last_name} {row?.is_vip==1 && <Icon className="ml-2 mb-2"  icon="quill:vip" fontSize={30} color='#ffbf00'></Icon>}</div>,
    },
    {
      header: 'DOB',
      cell: (row) => <>{row?.address?.dob}</>,
    },
    {
      header: 'Status',
      cell: (row) => <div className='flex flex-row'>  <Badge color={(row?.deleted_at==''||row?.deleted_at==null)?'success':'failure'}>{(row?.deleted_at==null||row?.deleted_at=='')?'Active':'InActive'}</Badge></div>,
    },
    {
      header: 'Location',
      cell: (row) => <div className='flex flex-row'> {row?.patientlocation?.length>0?
        <div className="bg-red-800 text-white rounded " >
              <span className=" text-white m-1 uppercase font-bold">{CityCodes[row?.patientlocation[0]?.location_name]||row?.patientlocation[0]?.location_name}</span>
            </div>
        :'-'}</div>,
    },
    {
      header: 'Current Employer',
      cell: (row) => <div className='flex flex-row'>  {row?.location_group?.group_name&&<Badge color="info">{row?.location_group?.group_name}</Badge>}</div>,
    },
    
    {
      header: 'Relationship',
      cell: (row) => <>{row?.address?.relationship}</>,
    },
    {
      header: '',
      cell: (row) => (
        <div className='text-sm text-everglade-800'>
          <Link
            to={`/patientDetails/${row.id}`}
            className='text-everglade-800 font-bold py-2 px-3 mx-2  rounded'
            onClick={viewDetail}
          >
            <AiOutlineEye
              size={24}
              className='cursor-pointer'
            />
          </Link>
        </div>
      ),
    },
  ]
  const viewDetail = () => {
    dispatch(togglePatientDetail(true))
  }

  function Filters(id) {
    switch (id) {
      case 1:
        return 'Available'
      case 2:
        return 'Unvailable'
      default:
        return 'All'
    }
  }

  const [isOpen, setIsOpen] = useState(false)
  const tagDropDownRef = useRef(null)
  const [isGroupOpen, setIsGroupOpen] = useState(false)
  const groupDropDownRef = useRef(null)



  const toggleDropdown = () => {
    setIsOpen((prev) => !prev)
  }
  const toggleGroupDropdown = () => {
    setIsGroupOpen((prev) => !prev)
  }

  const handleChange = (event) => {
    // console.log(event.target.value,Patientfilter?.patient_tags[event.target.value]?.tag_name)
    // setPatientFilterval({ ...PatientFilterval, tag: event.target.value.toString() })
    // setSelectedItemName(Patientfilter?.patient_tags[event.target.value]?.tag_name)
    // setIsOpen(false)
  const selectedTagId = event.target.value;
  const selectedTag = Patientfilter?.patient_tags.find((tag) => tag.id === parseInt(selectedTagId));
  setPatientFilterval({ ...PatientFilterval, tag: selectedTagId });
  setSelectedItemName(selectedTag?.tag_name); 
  }

  const handleGroupChange = (value) => {
    setPatientFilterval({ ...PatientFilterval, group: value.target.value })
    setSelectedGroupName(Patientfilter?.groups[value.target.value])
    setIsGroupOpen(false)
  }

  const handleTagClickOutside = (event) => {
    if (
      tagDropDownRef.current &&
      !tagDropDownRef.current.contains(event.target)
    ) {
      setIsOpen(false)
    }
  }
  const handleGroupClickOutside = (event) => {
    if (
      groupDropDownRef.current &&
      !groupDropDownRef.current.contains(event.target)
    ) {
      setIsGroupOpen(false)
    }
  }

  useEffect(() => {
    document.addEventListener('click', handleTagClickOutside)
    document.addEventListener('click', handleGroupClickOutside)

    return () => {
      document.removeEventListener('click', handleTagClickOutside)
      document.removeEventListener('click', handleGroupClickOutside)

    }
  }, [])

   useEffect(()=>{
    setPatientFilterval({ ...PatientFilterval, patient_login: '1' })
   // eslint-disable-next-line react-hooks/exhaustive-deps
   },[])
  


  return (
    <div className='w-full'>
      <div className='w-full h-[106%] overflow-x-hidden pt-4'>
        <div className=' mx-auto '>
          <section className=''>                  
            <Table
              loader={isLoading}
              count={0}
              defaultPage={1}
              page={currentPage}
              onChange={handlePageChange}
              totalPages={PatientListinfo?.data?.total_pages}
              columns={columns}
              data={PatientList}
              header={() => {
                return (
                  <section className='w-full  grid grid-cols-1 lg:grid-cols-1 gap-3 justify-between items-center'>
                    <h1 className='text-base w-[100px] font-semibold text-everglade-900 pl-2 shrink-0 '>
                      Patient List
                    </h1>
                    {PatientList &&
                    <Accordion className={`w-full px-0 flex col-span-3 flex-wrap items-center`}>
                      <Accordion.Panel>
                        <Accordion.Title className="w-full text-everglade-900">Filter</Accordion.Title> {/* Ensures the title is full width */}
                        <Accordion.Content className="w-full"> {/* Ensures content matches the parent's width */}
                          <div className="grid grid-flow-col justify-stretch space-x-4 ">
                            <div className="relative z-0">
                              <select id="floating_standard" value={PatientFilterval?.patient_login} onChange={FilterType} className="block py-2.5 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-everglade-900 appearance-none dark:text-white dark:border-everglade-600 dark:focus:border-everglade-500 focus:outline-none focus:ring-0 focus:border-everglade-600 peer" placeholder=" ">
                                <option value=" ">All</option>
                                <option value="1">Available</option>
                                <option value="2">Unavailable</option>
                              </select>
                              <label for="floating_standard" className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 peer-focus:text-everglade-600 peer-focus:dark:text-everglade-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto">Availablity</label>
                            </div>
                            <div className="relative z-0">
                              <input type="date" name="dob" onChange={handleDOBChanges} id="floating_standard" className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-everglade-900 appearance-none dark:text-white dark:border-everglade-600 dark:focus:border-everglade-500 focus:outline-none focus:ring-0 focus:border-everglade-600 peer" placeholder=" " />
                              <label for="floating_standard" className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 peer-focus:text-everglade-600 peer-focus:dark:text-everglade-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto">DOB</label>
                            </div>
                           
                            {Patientfilter?.patient_tags.length>0&&<div className="relative z-0">
                              <select id="floating_standard" value={PatientFilterval?.tag } onChange={handleChange} className="block py-2.5 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-everglade-900 appearance-none dark:text-white dark:border-everglade-600 dark:focus:border-everglade-500 focus:outline-none focus:ring-0 focus:border-everglade-600 peer" placeholder=" ">
                              <option key={'Patient Tag'} value=''>{'Patient Tag'}</option>
                                {Patientfilter?.patient_tags.map((item,idx) => (
                                <option key={item.id} value={item.id}>{item.tag_name}</option>
                              ))}
                              </select>
                              <label for="floating_standard" className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 peer-focus:text-everglade-600 peer-focus:dark:text-everglade-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto">Tags</label>
                            </div>}
                            {Patientfilter?.groups&&<div className="relative z-0">
                              <select id="floating_standard" value={PatientFilterval?.group } onChange={handleGroupChange} className="block py-2.5 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-everglade-900 appearance-none dark:text-white dark:border-everglade-600 dark:focus:border-everglade-500 focus:outline-none focus:ring-0 focus:border-everglade-600 peer" placeholder=" ">
                              <option key={'Patient Group'} value=' '>{'Patient Group'}</option>
                                   {Object.entries(Patientfilter.groups).map(([key,value],idx)=>(
                                // {value && <option key={key} value={value}>{value}</option>}
                                // <option key={key} value={value}>{value}</option>
                                value && <option key={key} value={key}>{value}</option>
                              ))}
                              </select>
                              <label for="floating_standard" className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 peer-focus:text-everglade-600 peer-focus:dark:text-everglade-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto">Groups</label>
                            </div>}
                            <div className="relative z-0">
                              <select id="floating_standard" value={PatientFilterval?.status} onChange={handleStatusChanges}className="block py-2.5 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-everglade-900 appearance-none dark:text-white dark:border-everglade-600 dark:focus:border-everglade-500 focus:outline-none focus:ring-0 focus:border-everglade-600 peer" placeholder=" ">
                                <option value=" ">All</option>
                                <option key="1" value="active">Active</option>
                                <option key="2" value="inactive">InActive</option>
                              </select>                              
                              <label for="floating_standard" className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 peer-focus:text-everglade-600 peer-focus:dark:text-everglade-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto">Status</label>
                            </div>
                            {Patientfilter?.locations&&
                            <div className="relative z-0">
                            <MultiSelect component="Locations" options={Patientfilter?.locations} property={'location_name'} handleSelection={handleSelectedLocations}  />
                            <label for="floating_standard" className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 peer-focus:text-everglade-600 peer-focus:dark:text-everglade-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto">Locations</label>
                            </div>}
                
                            {Patientfilter?.teams&&<div className="relative z-0">                           
                              <MultiSelect component="Teams" options={Patientfilter?.teams} property={'tname'} handleSelection={handleSelectedTeams}  />
                              <label for="floating_standard" className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 peer-focus:text-everglade-600 peer-focus:dark:text-everglade-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto">Teams</label>
                            </div>}
                          </div>
                          <div className="grid grid-flow-col justify-stretch space-x-5 mt-3">
                            <div className="relative ">
                              <input type="text" id="floating_standard" value={PatientFilterval.search} onChange={handleChangesearch} className="w-1/2 block rounded-t-lg px-2.5 pb-2.5 pt-5 text-sm text-gray-900 dark:bg-gray-700 border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-everglade-500 focus:outline-none focus:ring-0 focus:border-everglade-600 peer" placeholder=" " />
                              <label for="floating_standard" className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-4 z-10 origin-[0] start-2.5 peer-focus:text-everglade-600 peer-focus:dark:text-everglade-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto">Search By Name</label>
                            </div>
                            <button type="button" onClick={handlefilter} className="text-white bg-everglade-700 hover:bg-everglade-800 focus:ring-4 focus:ring-everglade-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-everglade-600 dark:hover:bg-everglade-700 focus:outline-none dark:focus:ring-everglade-800">Filter</button>
                          </div>
                        </Accordion.Content>
                      </Accordion.Panel>
                    </Accordion>
                    }
                  </section>
                )
              }}
            />
            {PatientList?.length > 0 && (
              <div className='pagination my-3 flex  justify-center pr-4 w-full md:justify-end'>
                {PatientListinfo?.data?.total_pages > 0 && !isLoading && (
                  <Pagination
                    count={10}
                    defaultPage={1}
                    page={currentPage}
                    onChange={handlePageChange}
                    totalPages={PatientListinfo?.data?.total_pages}
                    totalCount={PatientListinfo?.data.total_count}
                  />
                )}
              </div>
            )}
          </section>
        </div>
      </div>
    </div>
  )
}

export default PatientList
